import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as data from '../../../url.json'
import swal from "sweetalert2";

@Injectable()
export class AuthService implements OnInit {
  url: string = data['default']['url'];
  isAuth: boolean = false;
  userId;
  user;
  userRole;
  currentSite;
  tableName;
  userImageUrl;
  username;
  userFunctions;

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(private http: HttpClient, private route: Router) {
    this.check();
  }

  ngOnInit(): void {
  }

  login(username, password) {
    this.http.get(`${this.url}/user/verify/${username}/${password}`).toPromise().then((res) => {
      if (res) {
        this.user = res;
        this.userId = this.user["userId"];
        this.username = this.user["username"];
        this.userImageUrl = this.user["imageUrl"];
        this.userRole = this.user["role"];
        sessionStorage.setItem('userId', this.userId);
        sessionStorage.setItem('userRole', this.userRole);

        this.http
          .get(`${this.url}/user/getToken/${this.userId}/${username}`)
          .toPromise()
          .then((data) => {
            if (data != null) {
              sessionStorage.setItem('token', JSON.stringify(data));
              this.isAuth = true;
              this.success(this.user);
              this.http.get(`${this.url}/getRolesFonctions/${sessionStorage.getItem('userRole')}`).toPromise().then((res) => {
                this.userFunctions = res;
              });
              this.route.navigate(['dashboard']);
            } else { console.log("error") }
          });
      } else {
        swal.fire({
          title: "Mauvaises informations",
          text: "Veuillez vérifier vos informations",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  }

  success(obj) {
    swal.close();
    if (obj['prenom'] != null) {
      swal.fire({
        title: "Bonjour " + obj['prenom'] + " !",
        text: "Vous êtes connecté",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      swal.fire({
        title: "Bonjour " + obj['username'] + " !",
        text: "Vous êtes connecté",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }

  logout() {
    this.isAuth = false;
    sessionStorage.clear();
    this.route.navigate(['login'])
  }

  check() {
    if (sessionStorage.length > 0) {
      this.http
        .post(`${this.url}/authent/`, sessionStorage.getItem('token'), this.options)
        .toPromise()
        .then((response) => {
          if (response === true) {
            this.userId = sessionStorage.getItem('userId');
            this.http.get(`${this.url}/user/${this.userId}`).toPromise().then((res) => {
              this.user = res;
              this.userRole = res['role'];
              this.userImageUrl = res['imageUrl'];
            });
            this.isAuth = true;
            this.route.navigate(['dashboard']);
            this.http.get(`${this.url}/getRolesFonctions/${sessionStorage.getItem('userRole')}`).toPromise().then((res) => {
              this.userFunctions = res;
            });
          }
          else {
            this.isAuth = false;
          }
        });
    }
  }

  canSee(fonction: string) {
    if (this.userFunctions) {
      if (this.userFunctions.some(e => e["id_fonction"] == fonction)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
