import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import * as urlFromFile from '../../../../url.json'
import { AuthService } from 'src/app/components/_service/auth.service';
import { Router } from '@angular/router';
import { HuilesEtGraissesService } from 'src/app/components/_service/huilesEtGraisses.service';
import { ProduitsDeNettoyageEtDesinfectionService } from 'src/app/components/_service/produitsDeNettoyageEtDesinfection.service';
import { CollesAdjuvantsService } from 'src/app/components/_service/collesAdjuvants.service';
import { EncreEtVernisAgreesService } from 'src/app/components/_service/encreEtVernisAgrees.service';

@Component({
  selector: 'app-demandes',
  templateUrl: './demandes.component.html',
  styleUrls: ['./demandes.component.scss']
})
export class DemandesComponent implements OnInit {

  url: string = urlFromFile['default']['url'];

  allDemandes;
  allUsers;
  allFournisseurs;
  allSites;

  showAllDemandes = true;
  showDemande = false;
  currentDemande;

  stateFilter = "En attente de validation";
  typeFilter = "all";
  siteFilter = "0";

  currentDemandeDocuments;

  urlForGed = "";

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private route: Router,
    private huilesEtGraissesService: HuilesEtGraissesService,
    private produitsDeNettoyageEtDesinfectionService: ProduitsDeNettoyageEtDesinfectionService,
    private collesAdjuvantsService: CollesAdjuvantsService,
    private encreEtVernisAgreesService: EncreEtVernisAgreesService,
  ) { }

  ngOnInit(): void {
    this.getAllDemandes();
    this.getAllUsers();
    this.getAllFournisseurs();
    this.getAllSites();
    this.showAllDemandes = true;
    this.showDemande = false;
  }

  getAllSites() {
    this.http.get(`${this.url}/sites/0`).toPromise().then((res) => {
      this.allSites = res;
    });
  }

  getAllFournisseurs() {
    this.http.get(`${this.url}/fournisseur`).toPromise().then((res) => {
      this.allFournisseurs = res;
    });
  }

  getAllUsers() {
    this.http.get(`${this.url}/user`).toPromise().then((res) => {
      this.allUsers = res;
    });
  }

  getAllDemandes() {
    this.http.get(`${this.url}/get_with_state/${this.stateFilter}/${this.typeFilter}/${this.siteFilter}`).toPromise().then((res) => {
      this.allDemandes = res;
    });
  }

  getStatusStyle(status) {
    if (status == "En attente de validation") return "status status--warning";
    else if (status == "Valide") return "status status--success";
    else if (status == "Refusee") return "status status--danger";
  }

  getStatusIcon(status) {
    if (status == "En attente de validation") return "fa fa-clock";
    else if (status == "Valide") return "fa fa-check";
    else if (status == "Refusee") return "fa fa-times";
  }

  getTypeDemande(type) {
    if (type == "huilesEtGraisses") return "Huiles et graisses";
    else if (type == "encreEtVernisAgrees") return "Encres et vernis";
    else if (type == "CollesAdjuvants") return "Colles et adjuvants";
    else if (type == "produitsDeNettoyageEtDesinfection") return "Produits de nettoyage et désinfection";
  }

  getUserName(id) {
    for (let user of this.allUsers) {
      if (user.userId == id) return user.prenom + " " + user.nom;
    }
  }

  getFournisseurName(id) {
    for (let fournisseur of this.allFournisseurs) {
      if (fournisseur.id == id) return fournisseur.nomFournisseur;
    }
  }

  cleanDate(date) {
    if (date) return date.split('T')[0];
  }

  getSiteById(id) {
    for (let site of this.allSites) {
      if (site.IdSite == id) return site.TypeSite + " / " + site.NomSite;
    }
  }

  showDemandeDetails(demande) {
    this.showAllDemandes = false;
    this.showDemande = true;
    this.currentDemande = demande;
    this.currentDemande['id_utilisateur'] = this.getUserName(demande.id_utilisateur);
    this.currentDemande['idFournisseur'] = this.getFournisseurName(demande.id_fournisseur);
    this.currentDemande['site_demandeur'] = this.getSiteById(demande.site_demandeur);
    this.currentDemande['date_validation_souhaitee'] = this.cleanDate(demande.date_validation_souhaitee);
    if (this.currentDemande['type_demande'] == "huilesEtGraisses") this.authService.tableName = "huilesEtGraisses", this.urlForGed = "huiles";
    else if (this.currentDemande['type_demande'] == "produitsDeNettoyageEtDesinfection") this.authService.tableName = "produitsDeNettoyageEtDesinfection", this.urlForGed = "produits";
    else if (this.currentDemande['type_demande'] == "CollesAdjuvants") this.authService.tableName = "CollesAdjuvants", this.urlForGed = "colles";
    else if (this.currentDemande['type_demande'] == "encreEtVernisAgrees") this.authService.tableName = "encreEtVernisAgrees", this.urlForGed = "encres";
    this.getGed(demande.id_element);
  }

  goToModify() {
    if (this.authService.tableName == "huilesEtGraisses") {
      this.http.get(`${this.url}/huilesEtGraisses/get/${this.currentDemande.id_element}`).toPromise().then((res) => {
        this.huilesEtGraissesService.huileEtGraisse = res;
        this.huilesEtGraissesService.huileEtGraisse['nomFournisseur'] = this.currentDemande['idFournisseur'];
        this.authService.tableName = "huilesEtGraisses";
        this.huilesEtGraissesService.listeFournisseurs = this.allFournisseurs;
        this.huilesEtGraissesService.isDemande = true;
        this.route.navigate(['/modifier']);
      });
    } else if (this.authService.tableName == "produitsDeNettoyageEtDesinfection") {
      this.http.get(`${this.url}/produitsDeNettoyageEtDesinfection/get/${this.currentDemande.id_element}`).toPromise().then((res) => {
        this.produitsDeNettoyageEtDesinfectionService.produitsDeNettoyageEtDesinfection = res;
        this.produitsDeNettoyageEtDesinfectionService.produitsDeNettoyageEtDesinfection['nomFournisseur'] = this.currentDemande['idFournisseur'];
        this.authService.tableName = "produitsDeNettoyageEtDesinfection";
        this.produitsDeNettoyageEtDesinfectionService.listeFournisseurs = this.allFournisseurs;
        this.produitsDeNettoyageEtDesinfectionService.isDemande = true;
        this.route.navigate(['/modifier']);
      });
    } else if (this.authService.tableName == "CollesAdjuvants") {
      this.http.get(`${this.url}/CollesEtAdjuvants/get/${this.currentDemande.id_element}`).toPromise().then((res) => {
        this.collesAdjuvantsService.colleAdjuvant = res;
        this.collesAdjuvantsService.colleAdjuvant['nomFournisseur'] = this.currentDemande['idFournisseur'];
        this.authService.tableName = "CollesAdjuvants";
        this.collesAdjuvantsService.listeFournisseurs = this.allFournisseurs;
        this.collesAdjuvantsService.isDemande = true;
        this.route.navigate(['/modifier']);
      });
    } else if (this.authService.tableName == "encreEtVernisAgrees") {
      this.http.get(`${this.url}/encreEtVernisAgree/get/${this.currentDemande.id_element}`).toPromise().then((res) => {
        this.encreEtVernisAgreesService.encreEtVernisAgree = res;
        this.encreEtVernisAgreesService.encreEtVernisAgree['nomFournisseur'] = this.currentDemande['idFournisseur'];
        this.authService.tableName = "encreEtVernisAgrees";
        this.encreEtVernisAgreesService.listeFournisseurs = this.allFournisseurs;
        this.encreEtVernisAgreesService.isDemande = true;
        this.route.navigate(['/modifier']);
      });
    }
  };

  validateDemande() {
    const obj = {
      admin: sessionStorage.getItem('userId'),
      date_answer: new Date()
    }
    this.http.post(`${this.url}/validate_demande/${this.currentDemande.id_demande}`, obj).toPromise().then((res) => {
      swal.fire({
        title: 'Demande validée',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      this.ngOnInit();
    });
  }

  refuseDemande() {
    this.http.get(`${this.url}/refuse_demande/${this.currentDemande.id_demande}`).toPromise().then((res) => {
      swal.fire({
        title: 'Demande refusée',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      this.ngOnInit();
    });
  }

  filter(event) {
    this.http.get(`${this.url}/get_with_state/${this.stateFilter}/${this.typeFilter}/${this.siteFilter}`).toPromise().then((res) => {
      this.allDemandes = res;
    });
  }

  getGed(id) {
    this.http.get(`${this.url}/file/${this.urlForGed}/getFilesNameById/${id}`).toPromise().then((res) => {
      this.currentDemandeDocuments = res;
      console.log(this.currentDemandeDocuments)
    });
  }
}