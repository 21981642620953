import { Routes } from "@angular/router";
import { ProduitsDeNettoyageEtDesinfectionComponent } from "./produitsDeNettoyageEtDesinfection.component";

export const ProduitsDeNettoyageEtDesinfectionRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: ProduitsDeNettoyageEtDesinfectionComponent
      }
    ]
  }
];
