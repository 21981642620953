import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { EncreEtVernisAgreesComponent } from "./encreEtVernisAgrees.component";
import { EncreEtVernisAgreesRoutes } from "./encreEtVernisAgrees.routing";
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(EncreEtVernisAgreesRoutes),
  ],
  declarations: [EncreEtVernisAgreesComponent],
  exports: [EncreEtVernisAgreesComponent, MatPaginatorModule, NgxDatatableModule, FormsModule],
})

export class EncreEtVernisAgreesModule {
}