import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/components/_service/auth.service';
import * as urlFromFile from '../../../../url.json'
import swal from 'sweetalert2';
import { HuilesEtGraissesService } from 'src/app/components/_service/huilesEtGraisses.service';
import { EncreEtVernisAgreesService } from 'src/app/components/_service/encreEtVernisAgrees.service';
import { ProduitsDeNettoyageEtDesinfectionService } from 'src/app/components/_service/produitsDeNettoyageEtDesinfection.service';
import { CollesAdjuvantsService } from 'src/app/components/_service/collesAdjuvants.service';
import { FournisseursPapiersAgreeService } from 'src/app/components/_service/fournisseursPapiersAgree.service';
import { FournisseursPapierService } from 'src/app/components/_service/fournisseursPapier.service';
import { FournisseursService } from 'src/app/components/_service/fournisseurs.service';

@Component({
  selector: 'app-ajout',
  templateUrl: './ajout.component.html',
  styleUrls: ['./ajout.component.css'],
})

export class ajoutComponent implements OnInit {

  url: string = urlFromFile['default']['url']; // Récupération de l'url du fichier url.json

  table; // Récupération du nom de la table
  title; // Titre de la page
  routing; // Chemin de la page
  backUrl; // Url à utiliser dans le backend
  imageUrl; // Url de l'image
  parameters; // Paramètres de la table

  columns: Object[] = []; // Liste des composants pour la table
  data; // Données de la table
  lastId; // Id de l'objet créé
  allFournisseurs; // Liste des fournisseurs
  commentaireJustificatif: boolean = false; // Commentaire justificatif

  constructor(private route: Router, private http: HttpClient, private authService: AuthService,
    private huilesEtGraissesService: HuilesEtGraissesService, private encreEtVernisAgreesService: EncreEtVernisAgreesService,
    private produitDeNettoyageEtDesifectionService: ProduitsDeNettoyageEtDesinfectionService, private collesEtAdjuvantsService: CollesAdjuvantsService,
    private fournisseursPapierAgreesService: FournisseursPapiersAgreeService, private founisseursPapierService: FournisseursPapierService,
    private fournisseurs: FournisseursService) { }

  // Explications de la fonction ngOnInit() : 
  // 1. On affiche une fenêtre de chargement
  // 2. On vérifie le nom de la table
  // 3. On récupère la configuration de la table
  // 4. On initialise la liste des composants pour la table
  // 5. On récupère la liste des fournisseurs
  // 6. On ferme la fenêtre de chargement

  ngOnInit(): void {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
    this.table = this.authService.tableName;
    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    });
    this.getConf();

    if (this.table === "huilesEtGraisses") {

      this.title = "Huiles et graisses";
      this.imageUrl = "../../../../assets/img/oil-can-solid.svg"
      this.routing = "huilesEtGraisses";
      this.backUrl = "huilesEtGraisses";
      this.fournisseurs.initFournisseurs().then(() => {
        this.allFournisseurs = this.fournisseurs.fournisseurs;
        swal.close();
      });
    }
    else if (this.table === "encreEtVernisAgrees") {

      this.title = "Encres et vernis";
      this.imageUrl = "../../../../assets/img/brush-solid.svg"
      this.routing = "encreEtVernisAgrees";
      this.backUrl = "encreEtVernisAgree";
      this.fournisseurs.initFournisseurs().then(() => {
        this.allFournisseurs = this.fournisseurs.fournisseurs;
        swal.close();
      });
    }
    else if (this.table === "produitsDeNettoyageEtDesinfection") {

      this.title = "Produit de nettoyage et désinfection";
      this.imageUrl = "../../../../assets/img/pump-soap-solid.svg"
      this.routing = "produitsDeNettoyageEtDesinfection";
      this.backUrl = "produitsDeNettoyageEtDesinfection";
      this.produitDeNettoyageEtDesifectionService.initProduitsDeNettoyageEtDesinfection().then(() => {
        this.allFournisseurs = this.produitDeNettoyageEtDesifectionService.listeFournisseurs;
        swal.close();
      });
    }
    else if (this.table === "CollesAdjuvants") {

      this.title = "Colles et adjuvants";
      this.imageUrl = "../../../../assets/img/droplet-solid.svg"
      this.routing = "collesAdjuvants";
      this.backUrl = "collesEtAdjuvants";
      this.fournisseurs.initFournisseurs().then(() => {
        this.allFournisseurs = this.fournisseurs.fournisseurs;
        swal.close();
      });
    }
    else if (this.table === "fournisseursPapiersAgrees") {

      this.title = "Papiers agréés";
      this.imageUrl = "../../../../assets/img/newspaper-solid.svg"
      this.routing = "fournisseursPapiersAgree";
      this.backUrl = "fournisseurPapiersAgree";
      this.fournisseursPapierAgreesService.initFournisseursPapiersAgreeService().then(() => {
        this.allFournisseurs = this.fournisseursPapierAgreesService.listeFournisseurs;
        swal.close();
      });
    }
    else if (this.table === "fournisseursPapier") {

      this.title = "Liste fournisseurs papier";
      this.imageUrl = "../../../../assets/img/truck-solid.svg"
      this.routing = "fournisseursPapier";
      this.backUrl = "fournisseursPapier";
      this.founisseursPapierService.initFournisseursPapier().then(() => {
        swal.close();
      });
    }
    else if (this.table === "fournisseurs") {

      this.title = "Liste fournisseurs produits chimiques";
      this.imageUrl = "../../../../assets/img/flask-vial-solid.svg"
      this.routing = "fournisseurs";
      this.backUrl = "fournisseur"
      this.fournisseurs.initFournisseurs().then(() => {
        swal.close();
      });
    }
    else {
      this.route.navigate(["/"]);
    }
  }

  // Explications de la fonction getConf() :
  // 1. On récupère les colonnes de la table
  // 2. On récupère les paramètres de la table

  getConf() {
    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
      this.parameters = this.data["parameters"][0];
      for (let i = 0; i < this.data["data"].length; i++) {
        for (let j = 0; j < this.data.data[i]["whereToDisplay"].length; j++) {
          if (this.data.data[i]["whereToDisplay"][j]["nameOfTab"] === this.table) {
            this.columns.push(this.data.data[i]);
          }
        }
      }
    });
  }

  // Explications de la fonction onSubmit() :
  // 1. On récupère les données du formulaire
  // 2. On ajoute la valeur "false" à la colonne "archive"
  // 3. On envoie les données au serveur
  // 4. On récupère l'id de l'objet créé
  // 5. On redirige vers la page de modification de l'objet créé

  async onSubmit(form: NgForm) {
    swal.fire({
      icon: 'warning',
      title: "Êtes-vous sûr de vouloir ajouter cet élément ?",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        form.value["archive"] = "false";

        // Modification de la valeur de commentaire_validite en fonction de la date de validité
        const today: Date = new Date();
        let date: Date = new Date(form.value["validite"]);
        if (form.value["commentaite_validite"] === "") {
          if (date < today) {
            if (this.table === "CollesAdjuvants" || this.table === "encreEtVernisAgrees" || this.table === "huilesEtGraisses" || this.table === "produitsDeNettoyageEtDesinfection") {
            }
            else {
              form.value["commentaire_validite"] = "Certificat périmé"
            }
          } else if (date > today) {
            form.value["commentaire_validite"] = "OK"
          }
        }

        // Modification de la valeur de validité en fonction de la date de dernière vérification et de la durée de validité présente dans le conf.json //

        if (form.value["validite"] === "") {
          if (this.table === "encreEtVernisAgrees" || this.table === "CollesAdjuvants" || this.table === "huilesEtGraisses" || this.table === "produitsDeNettoyageEtDesinfection") {
            let date_document: Date = new Date(form.value["date_document"]);
            let derniere_verification: Date = new Date(form.value["derniere_verification"]);
            for (let i = 0; i < this.parameters["tables"].length; i++) {
              if (this.parameters["tables"][i]["bddName"] === this.table) {
                let nbYear = this.parameters["tables"][i]["year"];
                derniere_verification.setFullYear(derniere_verification.getFullYear() + nbYear);
              }
            }
            form.value["validite"] = derniere_verification;
            date = new Date(form.value["validite"]);
            if (date < today) {
              form.value["commentaire_validite"] = "Certificat caduque"
            } else if (date > today) {
              form.value["commentaire_validite"] = "Ok"
            }
          }
        }

        return this.http.post(`${this.url}/${this.backUrl}`, form.value).toPromise().then((res) => {
          this.lastId = res;
          swal.fire({
            icon: 'success',
            title: "L'élément a été ajouté !",
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            this.redirect();
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          icon: 'error',
          title: "L'élément n'a pas été ajouté !",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          swal.close();
        });
      }
    });
  }

  // Explications de la fonction redirect() :
  // 1. On redirige vers la page de la table

  redirect() {
    this.route.navigate([this.routing]);
  }

  checkCommentaireValidite(event) {
    if (event.target.value.match(/Référence non.*/)) {
      this.commentaireJustificatif = true;
    } else {
      this.commentaireJustificatif = false;
    }
  }
}