import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { AuthGuard } from "./components/_service/authGuard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",

  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./pages/examples/dashboard/dashboard.module#DashboardModule",
        canActivate: [AuthGuard]
      },
      {
        path: "components",
        loadChildren:
          "./pages/examples/components/components.module#ComponentsPageModule"
      },
      {
        path: "forms",
        loadChildren: "./pages/examples/forms/forms.module#Forms"
      },
      {
        path: "tables",
        loadChildren: "./pages/examples/tables/tables.module#TablesModule"
      },
      {
        path: "maps",
        loadChildren: "./pages/examples/maps/maps.module#MapsModule"
      },
      {
        path: "widgets",
        loadChildren: "./pages/examples/widgets/widgets.module#WidgetsModule"
      },
      {
        path: "charts",
        loadChildren: "./pages/examples/charts/charts.module#ChartsModule",
        canActivate: [AuthGuard]
      },
      {
        path: "",
        loadChildren:
          "./pages/examples/pages/timeline/timeline.module#TimelineModule"
      },
      {
        path: "login",
        loadChildren:
          "./pages/examples/LogIn/login.module#LoginModule"
      },
      {
        path: "profil",
        loadChildren:
          "./pages/examples/profil/profil.module#ProfilModule"
      },
      {
        path: "fournisseursPapiersAgree",
        loadChildren:
          "./pages/examples/Table/fournisseursPapiersAgree/fournisseursPapiersAgree.module#FournisseursPapiersAgreeModule",
        canActivate: [AuthGuard]
      },
      {
        path: "fournisseursPapier",
        loadChildren:
          "./pages/examples/Table/fournisseursPapier/fournisseursPapier.module#FournisseursPapierModule",
        canActivate: [AuthGuard]
      },
      {
        path: "addFournisseursPapier",
        loadChildren:
          "./pages/examples/add-fournisseursPapier/addFournisseursPapier.module#addFournisseursPapierModule",
        canActivate: [AuthGuard]
      },
      {
        path: "fournisseurs",
        loadChildren:
          "./pages/examples/Table/fournisseurs/fournisseurs.module#FournisseursModule",
        canActivate: [AuthGuard]
      },
      {
        path: "collesAdjuvants",
        loadChildren:
          "./pages/examples/Table/collesAdjuvants/collesAdjuvants.module#collesAdjuvantsModule",
        canActivate: [AuthGuard]
      },
      {
        path: "encreEtVernisAgrees",
        loadChildren:
          "./pages/examples/Table/encreEtVernisAgrees/encreEtVernisAgrees.module#EncreEtVernisAgreesModule",
        canActivate: [AuthGuard]
      },
      {
        path: "huilesEtGraisses",
        loadChildren:
          "./pages/examples/Table/huilesEtGraisses/huilesEtGraisses.module#HuilesEtGraissesModule"
      },
      {
        path: "produitsDeNettoyageEtDesinfection",
        loadChildren:
          "./pages/examples/Table/produitsDeNettoyageEtDesinfection/produitsDeNettoyageEtDesinfection.module#ProduitsDeNettoyageEtDesinfectionModule"
      },
      {
        path: "parameters",
        loadChildren:
          "./pages/examples/parameters/parameters.module#ParametersModule"
      },
      {
        path: "adminParameters",
        loadChildren:
          "./pages/examples/admin-parameters/admin-parameters.module#AdminParametersModule",
        canActivate: [AuthGuard]
      },
      {
        path: "ajout",
        loadChildren:
          "./pages/examples/ajout/ajout.module#ajoutModule",
        canActivate: [AuthGuard]
      },
      {
        path: "modifier",
        loadChildren:
          "./pages/examples/modifier/modifier.module#modifierModule",
        canActivate: [AuthGuard]
      },
      {
        path: "creer-demande",
        loadChildren:
          "./pages/examples/creer-demande/creer-demande.module#CreerDemandeModule",
        canActivate: [AuthGuard]
      },
      {
        path: "demandes",
        loadChildren:
          "./pages/examples/demandes/demandes.module#DemandesModule",
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/pages.module#PagesModule"
      }
    ]
  },
  {
    path: "",
    component: RtlLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/rtl/rtl.module#RtlModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "dashboard"
  }
];

@NgModule({
  imports: [
    CommonModule,
    //BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
