import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { DemandesComponent } from "./demandes.component";
import { DemandesRoute } from "./demandes.routing";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(DemandesRoute)],
  declarations: [DemandesComponent],
  exports: [DemandesComponent, FormsModule],
})

export class DemandesModule {
}
