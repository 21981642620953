import { Routes } from "@angular/router";
import { EncreEtVernisAgreesComponent } from "./encreEtVernisAgrees.component";

export const EncreEtVernisAgreesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: EncreEtVernisAgreesComponent
      }
    ]
  }
];