import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/components/_service/auth.service';
import * as urlFromFile from '../../../../url.json'
import swal from 'sweetalert2';
import { HuilesEtGraissesService } from 'src/app/components/_service/huilesEtGraisses.service';
import { EncreEtVernisAgreesService } from 'src/app/components/_service/encreEtVernisAgrees.service';
import { ProduitsDeNettoyageEtDesinfectionService } from 'src/app/components/_service/produitsDeNettoyageEtDesinfection.service';
import { CollesAdjuvantsService } from 'src/app/components/_service/collesAdjuvants.service';
import { FournisseursPapiersAgreeService } from 'src/app/components/_service/fournisseursPapiersAgree.service';
import { FournisseursPapierService } from 'src/app/components/_service/fournisseursPapier.service';
import { FournisseursService } from 'src/app/components/_service/fournisseurs.service';

@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.css'],
})

export class modifierComponent implements OnInit {
  url: string = urlFromFile['default']['url']; // Récupération de l'url du fichier url.json

  table; // Récupération du nom de la table
  title; // Titre de la page
  routing; // Chemin de la page
  backUrl; // Url à utiliser dans le backend
  imageUrl; // Url de l'image
  currentElement; // Objet à modifier
  currentElementId; // Id de l'objet à modifier
  sitesOfElement; // Liste des sites de l'objet à modifier
  sitesToAdd = [];
  sitesToRemove = [];
  nbSites = 0;
  checkAll = false;

  tab; // Liste des fournisseurs
  columns: Object[] = []; // Liste des composants pour la table
  allSites; // Liste des sites
  data; // Données de la table
  lastId; // Id de l'objet créé

  constructor(private route: Router,
    private http: HttpClient, private authService: AuthService,
    private huilesEtGraissesService: HuilesEtGraissesService, private encreEtVernisAgreesService: EncreEtVernisAgreesService,
    private produitDeNettoyageEtDesifectionService: ProduitsDeNettoyageEtDesinfectionService, private collesEtAdjuvantsService: CollesAdjuvantsService,
    private fournisseursPapierAgreesService: FournisseursPapiersAgreeService, private founisseursPapierService: FournisseursPapierService,
    private fournisseurs: FournisseursService) {
  }

  // Explication de ngOnit() :
  // 1. On récupère le nom de la table
  // 2. On récupère les données de la table
  // 3. On récupère les composants à afficher pour la table
  // 4. On récupère les sites
  // 5. On récupère les fournisseurs

  ngOnInit(): void {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
    this.allSites = [];
    this.table = this.authService.tableName;
    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    });
    if (this.authService.userRole != "admin") {
      this.http.get(`${this.url}/sites/${this.authService.userId}`).toPromise().then((res) => {
        this.allSites = res;
      });
    } else if (this.authService.userRole == "admin") {
      this.http.get(`${this.url}/sites/0`).toPromise().then((res) => {
        this.allSites = res;
      });
    }

    this.getConf();

    if (this.table === "huilesEtGraisses") {
      if (!this.authService.canSee('update_huiles_graisses')) this.route.navigate(['/dashboard']);
      this.title = "Huiles et graisses";
      this.imageUrl = "../../../../assets/img/oil-can-solid.svg"
      this.routing = "huilesEtGraisses";
      this.backUrl = "huilesEtGraisses";
      this.currentElement = this.huilesEtGraissesService.huileEtGraisse;
      this.currentElementId = "idHuile";
      this.tab = this.huilesEtGraissesService.listeFournisseurs;

      this.huilesEtGraissesService.initHuilesEtGraisses().then((response) => {
        swal.close()
      });
    }
    else if (this.table === "produitsDeNettoyageEtDesinfection") {
      if (!this.authService.canSee('update_produits_nettoyage')) this.route.navigate(['/dashboard']);
      this.title = "Produit de nettoyage et désinfection";
      this.imageUrl = "../../../../assets/img/pump-soap-solid.svg"
      this.routing = "produitsDeNettoyageEtDesinfection";
      this.backUrl = "produitsDeNettoyageEtDesinfection";
      this.currentElement = this.produitDeNettoyageEtDesifectionService.produitsDeNettoyageEtDesinfection;
      this.currentElementId = "idProduitsNettoyageDesinfection";
      this.tab = this.produitDeNettoyageEtDesifectionService.listeFournisseurs;

      this.produitDeNettoyageEtDesifectionService.initProduitsDeNettoyageEtDesinfection().then(() => {
        swal.close();
      });
    }
    else if (this.table === "CollesAdjuvants") {
      if (!this.authService.canSee('update_colles_adjuvants')) this.route.navigate(['/dashboard']);
      this.title = "Colles et adjuvants";
      this.imageUrl = "../../../../assets/img/droplet-solid.svg"
      this.routing = "collesAdjuvants";
      this.backUrl = "collesEtAdjuvants";
      this.currentElement = this.collesEtAdjuvantsService.colleAdjuvant;
      this.currentElementId = "idColles";
      this.tab = this.collesEtAdjuvantsService.listeFournisseurs;

      this.collesEtAdjuvantsService.initCollesAdjuvants().then(() => {
        swal.close();
      });
    }
    else if (this.table === "encreEtVernisAgrees") {
      if (!this.authService.canSee('update_encres_vernis')) this.route.navigate(['/dashboard']);
      this.title = "Encres et vernis";
      this.imageUrl = "../../../../assets/img/brush-solid.svg"
      this.routing = "encreEtVernisAgrees";
      this.backUrl = "encreEtVernisAgree";
      this.currentElement = this.encreEtVernisAgreesService.encreEtVernisAgree;
      this.currentElementId = "idEncres";
      this.tab = this.encreEtVernisAgreesService.listeFournisseurs;
      this.encreEtVernisAgreesService.initEncreEtVernisAgrees().then(() => {
        swal.close();
      });
    }
    else if (this.table === "fournisseursPapiersAgrees") {
      if (!this.authService.canSee('update_papier_agrees')) this.route.navigate(['/dashboard']);
      this.title = "Papiers agréés";
      this.imageUrl = "../../../../assets/img/newspaper-solid.svg"
      this.routing = "fournisseursPapiersAgree";
      this.backUrl = "fournisseurPapiersAgree";
      this.currentElement = this.fournisseursPapierAgreesService.fournisseurPapierAgree;
      this.currentElementId = "idFournisseursPapiersAgrees";
      this.tab = this.fournisseursPapierAgreesService.listeFournisseurs;

      this.fournisseursPapierAgreesService.initFournisseursPapiersAgreeService().then(() => {
        swal.close();
      });
    }
    else if (this.table === "fournisseurs") {
      if (!this.authService.canSee('update_fournisseurs_chimiques')) this.route.navigate(['/dashboard']);
      this.title = "Liste fournisseurs produits chimiques";
      this.imageUrl = "../../../../assets/img/flask-vial-solid.svg"
      this.routing = "fournisseurs";
      this.backUrl = "fournisseur"
      this.currentElement = this.fournisseurs.fournisseurs;
      this.currentElementId = "idFournisseur";
      this.fournisseurs.initFournisseurs().then(() => {
        swal.close();
      });
    }
    else if (this.table === "fournisseursPapier") {
      if (!this.authService.canSee('update_fournisseurs_papier')) this.route.navigate(['/dashboard']);
      this.title = "Liste fournisseurs papier";
      this.imageUrl = "../../../../assets/img/truck-solid.svg"
      this.routing = "fournisseursPapier";
      this.backUrl = "fournisseursPapier";
      this.currentElement = this.founisseursPapierService.fournisseursPapier;
      this.currentElementId = "idFournisseurPapier";
      this.founisseursPapierService.initFournisseursPapier().then(() => {
        swal.close();
      });
    }
    else {

      swal.close();
      this.route.navigate(["/"]);
    }

    if (this.table !== "fournisseurs" && this.table !== "fournisseursPapier") {
      this.loadSites();
    }
  }

  // Explication de getConf() :
  // 1. On récupère les composants à afficher pour la table

  getConf() {
    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
      for (let i = 0; i < this.data["data"].length; i++) {
        for (let j = 0; j < this.data.data[i]["whereToDisplay"].length; j++) {
          if (this.data.data[i]["whereToDisplay"][j]["nameOfTab"] === this.table) {
            this.columns.push(this.data.data[i]);
          }
        }
      }
    });
  }

  // Explication de la fonction onSubmit
  // On récupère les données du formulaire
  // On les parse en JSON
  // On les envoie dans le back
  // On redirige vers la page de l'élement modifié

  async onSubmit(form: NgForm) {
    swal.fire({
      title: "Êtes-vous sûr de vouloir modifier l'élément ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.value) {
        if (this.table === "huilesEtGraisses") {
          this.huilesEtGraissesService.updateHuilesEtGraisses(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "produitsDeNettoyageEtDesinfection") {
          this.produitDeNettoyageEtDesifectionService.updateProduitsDeNettoyageEtDesinfection(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "CollesAdjuvants") {
          this.collesEtAdjuvantsService.updateColle(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "encreEtVernisAgrees") {
          this.encreEtVernisAgreesService.updateEncre(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "fournisseursPapiersAgrees") {
          this.fournisseursPapierAgreesService.updatePaper(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "fournisseurs") {
          this.fournisseurs.updateFournisseur(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else if (this.table === "fournisseursPapier") {
          this.founisseursPapierService.updateFournisseurPapier(JSON.parse(JSON.stringify(this.currentElement))).then((response) => {
            this.redirect();
          });
        }
        else {

          this.route.navigate(["/"]);
        }

        for (let i = 0; i < this.sitesToAdd.length; i++) {
          if (this.sitesToAdd.length > 0) {
            var obj = {
              "id": this.currentElement[this.currentElementId],
              "idSite": this.sitesToAdd[i]
            }
            this.http.post(`${this.url}/${this.backUrl}/addElementToSite`, obj).toPromise().then((res) => {
            });
          }
        }

        for (let i = 0; i < this.sitesToRemove.length; i++) {
          if (this.sitesToRemove.length > 0) {
            var obj = {
              "id": this.currentElement[this.currentElementId],
              "idSite": this.sitesToRemove[i]
            }
            this.http.post(`${this.url}/${this.backUrl}/removeElementFromSite`, obj).toPromise().then((res) => {
            });
          }
        }

      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: "Modification annulée",
          icon: "error",
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        }).then((result) => {
          swal.close();
        });
      }
    });
  }

  // Explication de loadSites() :
  // 1. On récupère les sites de l'objet à modifier

  loadSites() {
    this.sitesOfElement = [];
    if (this.table != "fournisseurs" || this.table != "fournisseursPapier") {
      this.http.get(`${this.url}/${this.backUrl}/getSitesById/${this.currentElement[this.currentElementId]}`).toPromise().then((res) => {
        for (let i = 0; i < Object.keys(res).length; i++) {
          this.sitesOfElement.push(res[i]["IdSite"]);
          this.nbSites++;
        }
      });
    }
  }

  // A chaque fois qu'on coche une checkbox, on ajoute ou on supprime l'idSite de l'objet à modifier

  checkedSite(idSite, event) {
    // check if the checkbox is checked
    if (event.target.checked) {
      // add the idSite to the array
      this.sitesToAdd.push(idSite);
      // remove the idSite from the array
      this.sitesToRemove = this.sitesToRemove.filter((item) => item !== idSite);
      this.nbSites++;
    } else {
      // remove the idSite from the array
      this.sitesToAdd = this.sitesToAdd.filter((item) => item !== idSite);
      // add the idSite to the array
      this.sitesToRemove.push(idSite);
      this.nbSites--;
    }
  }

  checkAllSites() {
    if (this.checkAll) {
      this.sitesToAdd = [];
      this.sitesToRemove = [];
      this.sitesOfElement = [];
      this.nbSites = 0;
      for (let i = 0; i < this.allSites.length; i++) {
        this.sitesToRemove.push(this.allSites[i]["IdSite"]);
      }
      this.checkAll = false;
    } else if (!this.checkAll) {
      this.sitesToAdd = [];
      this.sitesToRemove = [];
      this.sitesOfElement = [];
      this.nbSites = 0;
      for (let i = 0; i < this.allSites.length; i++) {
        this.sitesToAdd.push(this.allSites[i]["IdSite"]);
        this.nbSites++;
      }
      this.checkAll = true;
    }
  }

  // Explication de redirect() :
  // 1. On redirige vers la page de la table

  redirect() {
    this.route.navigate([this.routing]);
  }
}