import { Component, OnInit } from "@angular/core";
import { Router, Routes } from '@angular/router';
import { DashboardRoutes } from "./dashboard.routing";
import { FournisseursRoutes } from "../Table/fournisseurs/fournisseurs.routing";
import { EncreEtVernisAgreesRoutes } from "../Table/encreEtVernisAgrees/encreEtVernisAgrees.routing";
import { AuthService } from "../../../components/_service/auth.service";
import { ProduitsDeNettoyageEtDesinfectionRoutes } from "../Table/produitsDeNettoyageEtDesinfection/produitsDeNettoyageEtDesinfection.routing";
import { FournisseursPapiersAgreeRoutes } from "../Table/fournisseursPapiersAgree/fournisseursPapiersAgree.routing";
import { HttpClient } from '@angular/common/http';
import * as urlFromFile from '../../../../url.json'
import { NavbarComponent } from "src/app/components/navbar/navbar.component";
import swal from "sweetalert2";

@Component({
  selector: "app-dashboard",
  templateUrl: "dashboard.component.html"
})
export class DashboardComponent implements OnInit {
  public data: any;
  private nbEncresPerimees = 0;
  private nbCollesPerimees = 0;
  private nbNettPerimees = 0;
  private nbHuilesPerimees = 0;
  private nbDemandes = 0;
  allSites;
  currentSite;
  idSite = 0;
  nomSite;
  testSite = "Tous les sites";
  routes: Routes[] = [DashboardRoutes, FournisseursRoutes, FournisseursPapiersAgreeRoutes, EncreEtVernisAgreesRoutes, ProduitsDeNettoyageEtDesinfectionRoutes]
  url: string = urlFromFile['default']['url'];
  paramUserRole;

  constructor(private route: Router, private http: HttpClient, private authService: AuthService) {
  }

  redirect(value, index: number) {
    window.location.replace("#/" + value)
  }

  ngOnInit() {
    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    }).then(() => {
      this.fetchSites();
      this.fetchNbPerimes();
    });
    swal.close();
  }

  fetchSites() {
    if (sessionStorage.getItem('userRole') == "admin") {
      this.paramUserRole = 0;
    } else if (sessionStorage.getItem('userRole') != "admin") {
      this.paramUserRole = this.authService.userId;
    }
    this.http.get(`${this.url}/sites/${this.paramUserRole}`).toPromise().then((res) => {
      this.allSites = res;
    });
  }

  fetchNbPerimes() {
    this.http.get(`${this.url}/encreEtVernisAgree/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbEncresPerimees = this.data;
    });
    this.http.get(`${this.url}/CollesEtAdjuvants/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbCollesPerimees = this.data;

    });
    this.http.get(`${this.url}/produitsDeNettoyageEtDesinfection/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbNettPerimees = this.data;
    });
    this.http.get(`${this.url}/huilesEtGraisses/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbHuilesPerimees = this.data;
    });
    this.http.get(`${this.url}/count_all_demandes`).toPromise().then((res) => {
      this.data = res;
      this.nbDemandes = this.data;
    });
  }

  changeSite(event) {
    this.authService.currentSite = this.currentSite;
    this.nomSite = this.authService.currentSite.NomSite;
    this.idSite = this.authService.currentSite.IdSite;
    NavbarComponent.nomSite = this.authService.currentSite.NomSite;
    this.testSite = NavbarComponent.nomSite;

    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    });
    this.http.get(`${this.url}/encreEtVernisAgree/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbEncresPerimees = this.data;
    });

    this.http.get(`${this.url}/CollesEtAdjuvants/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbCollesPerimees = this.data;

    });

    this.http.get(`${this.url}/produitsDeNettoyageEtDesinfection/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbNettPerimees = this.data;
    });

    this.http.get(`${this.url}/huilesEtGraisses/nbperimes/${this.idSite}`).toPromise().then((res) => {
      this.data = res;
      this.nbHuilesPerimees = this.data;
      swal.close();
    });
  }
}
