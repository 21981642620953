import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HuilesEtGraissesComponent } from "./huilesEtGraisses.component";
import { HuilesEtGraissesRoutes } from "./huilesEtGraisses.routing";
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(HuilesEtGraissesRoutes),
  ],
  declarations: [HuilesEtGraissesComponent],
  exports: [HuilesEtGraissesComponent, MatPaginatorModule, NgxDatatableModule, FormsModule],
})

export class HuilesEtGraissesModule {
}