import { Routes } from "@angular/router";
import { ajoutComponent } from "./ajout.component";

export const ajoutRoute: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: ajoutComponent
      }
    ]
  }
];