import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/components/_service/auth.service';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FournisseursService } from 'src/app/components/_service/fournisseurs.service';
import { HttpClient } from '@angular/common/http';
import * as urlFromFile from '../../../../url.json'
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-creer-demande',
  templateUrl: './creer-demande.component.html',
  styleUrls: ['./creer-demande.component.scss']
})
export class CreerDemandeComponent implements OnInit {

  location: Location;
  allFournisseurs;
  allSites;
  url: string = urlFromFile['default']['url'];
  paramUserRole;
  elementId: number = 0;
  filesList = [];

  constructor(
    private authService: AuthService,
    private fournisseurs: FournisseursService,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('userRole') == "admin") {
      this.paramUserRole = 0;
    } else if (sessionStorage.getItem('userRole') != "admin") {
      this.paramUserRole = this.authService.userId;
    }

    this.fournisseurs.initFournisseurs().then(() => {
      this.allFournisseurs = this.fournisseurs.fournisseurs;
    });

    this.http.get(`${this.url}/sites/${this.paramUserRole}`).toPromise().then((res) => {
      this.allSites = res;
    });
  }

  redirect() {
    this.location.back();
  }

  async onSubmit(form: NgForm) {
    form.value["status"] = "En attente de validation";
    form.value["type_demande"] = this.authService.tableName;
    form.value["id_utilisateur"] = sessionStorage.getItem('userId');

    if (this.authService.tableName == "huilesEtGraisses") this.createHuile(form.value);
    else if (this.authService.tableName == "encreEtVernisAgrees") this.createEncre(form.value);
    else if (this.authService.tableName == "CollesAdjuvants") this.createColle(form.value);
    else if (this.authService.tableName == "produitsDeNettoyageEtDesinfection") this.createProduit(form.value);
    else this.router.navigate(['/dashboard']);
  }

  createDemande(id, form) {
    form["id_element"] = id;

    this.http.post(`${this.url}/create_demande`, form).toPromise().then((res) => {
      if (res != 0) {
        swal.fire({
          title: 'Demande créée avec succès',
          icon: 'success',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        });

        if (this.authService.tableName == "huilesEtGraisses") this.modifyHuile(id, res);
        else if (this.authService.tableName == "encreEtVernisAgrees") this.modifyEncre(id, res);
        else if (this.authService.tableName == "CollesAdjuvants") this.modifyColle(id, res);
        else if (this.authService.tableName == "produitsDeNettoyageEtDesinfection") this.modifyProduit(id, res);
        else this.router.navigate(['/dashboard']);
      }
    });
  }

  modifyHuile(id_huile, id_demande) {
    let obj = {
      id_huile: id_huile,
      id_demande: id_demande
    }

    this.http.put(`${this.url}/huilesEtGraisses/setIdDemande`, obj).toPromise().then((res) => {
      setTimeout(() => {
        this.router.navigate(['/huilesEtGraisses']);
      }, 1000);
    });
  }

  modifyEncre(id_encre, id_demande) {
    let obj = {
      id_encre: id_encre,
      id_demande: id_demande
    }

    this.http.put(`${this.url}/encreEtVernisAgree/setIdDemande`, obj).toPromise().then((res) => {
      setTimeout(() => {
        this.router.navigate(['/encreEtVernisAgrees']);
      }, 1000);
    });
  }

  modifyColle(id_colle, id_demande) {
    let obj = {
      id_colle: id_colle,
      id_demande: id_demande
    }

    this.http.put(`${this.url}/CollesEtAdjuvants/setIdDemande`, obj).toPromise().then((res) => {
      setTimeout(() => {
        this.router.navigate(['/CollesEtAdjuvants']);
      }, 1000);
    });
  }

  modifyProduit(id_produit, id_demande) {
    let obj = {
      id_produit: id_produit,
      id_demande: id_demande
    }

    this.http.put(`${this.url}/produitsDeNettoyageEtDesinfection/setIdDemande`, obj).toPromise().then((res) => {
      setTimeout(() => {
        this.router.navigate(['/produitsDeNettoyageEtDesinfection']);
      }, 1000);
    });
  }

  createHuile(form) {
    const huile = {
      "reference": form["reference"],
      "idFournisseur": form["idFournisseur"],
      "archive": 0
    }

    this.http.post(`${this.url}/huilesEtGraisses`, huile).toPromise().then((res: number) => {
      this.elementId = res;

      var obj = {
        "id": this.elementId,
        "idSite": form["site_demandeur"]
      }

      this.http.post(`${this.url}/huilesEtGraisses/addElementToSite`, obj).toPromise().then((res) => { });

      const formData = new FormData();

      for (let file of this.filesList) {
        formData.append('thumbnail', file);

        this.http.post(`${this.url}/file/huiles/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.elementId,
            idGed: res,
            creator: this.authService.user.prenom
          }

          this.http.post(`${this.url}/file/huiles/updateGED`, obj).toPromise().then((res2) => { });
        });

        formData.delete('thumbnail');
      }

      this.createDemande(this.elementId, form);
    });
  }

  createEncre(form) {
    const encre = {
      "reference": form["reference"],
      "idFournisseur": form["idFournisseur"],
      "archive": 0
    }

    this.http.post(`${this.url}/encreEtVernisAgree`, encre).toPromise().then((res: number) => {
      this.elementId = res;

      var obj = {
        "id": this.elementId,
        "idSite": form["site_demandeur"]
      }

      this.http.post(`${this.url}/encreEtVernisAgree/addElementToSite`, obj).toPromise().then((res) => { });

      const formData = new FormData();

      for (let file of this.filesList) {
        formData.append('thumbnail', file);

        this.http.post(`${this.url}/file/encres/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.elementId,
            idGed: res,
            creator: this.authService.user.prenom
          }

          this.http.post(`${this.url}/file/encres/updateGED`, obj).toPromise().then((res2) => { });
        });

        formData.delete('thumbnail');
      }

      this.createDemande(this.elementId, form);
    });
  }

  createColle(form) {
    const colle = {
      "reference": form["reference"],
      "idFournisseur": form["idFournisseur"],
      "archive": 0
    }

    this.http.post(`${this.url}/CollesEtAdjuvants`, colle).toPromise().then((res: number) => {
      this.elementId = res;

      var obj = {
        "id": this.elementId,
        "idSite": form["site_demandeur"]
      }

      this.http.post(`${this.url}/CollesEtAdjuvants/addElementToSite`, obj).toPromise().then((res) => { });

      const formData = new FormData();

      for (let file of this.filesList) {
        formData.append('thumbnail', file);

        this.http.post(`${this.url}/file/colles/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.elementId,
            idGed: res,
            creator: this.authService.user.prenom
          }

          this.http.post(`${this.url}/file/colles/updateGED`, obj).toPromise().then((res2) => { });
        });

        formData.delete('thumbnail');
      }

      this.createDemande(this.elementId, form);
    });
  }

  createProduit(form) {
    const produit = {
      "reference": form["reference"],
      "idFournisseur": form["idFournisseur"],
      "archive": 0
    }

    this.http.post(`${this.url}/produitsDeNettoyageEtDesinfection`, produit).toPromise().then((res: number) => {
      this.elementId = res;

      var obj = {
        "id": this.elementId,
        "idSite": form["site_demandeur"]
      }

      this.http.post(`${this.url}/produitsDeNettoyageEtDesinfection/addElementToSite`, obj).toPromise().then((res) => { });

      const formData = new FormData();

      for (let file of this.filesList) {
        formData.append('thumbnail', file);

        this.http.post(`${this.url}/file/produits/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.elementId,
            idGed: res,
            creator: this.authService.user.prenom
          }

          this.http.post(`${this.url}/file/produits/updateGED`, obj).toPromise().then((res2) => { });
        });

        formData.delete('thumbnail');
      }

      this.createDemande(this.elementId, form);
    });
  }

  addFiles(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.filesList.push(event.target.files[i]);
    }
  }
}