import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'

@Injectable()
export class RechercheService {
    // url: string = data['default']['url'];

    // recherche

    // constructor(private http: HttpClient) {
    //     this.initRecherche();
    // }

    // initRecherche() {
    //     this.recherche = []
    //     this.recherche.splice(0, this.recherche.length)
    //     return this.http
    //         .get(`${this.url}/rechercher/`)
    //         .toPromise()
    //         .then((response) => {
    //             this.recherche = response
    //         })
    // }

    // getWithFilter(filter) {
    //     this.recherche = []
    //     this.recherche.splice(0, this.recherche.length)
    //     if (+localStorage.getItem("sites") == 0 || localStorage.getItem("sites") == null) {
    //         return this.http
    //             .get(`${this.url}/rechercher/${filter}`)
    //             .toPromise()
    //             .then((response) => {
    //                 this.recherche = response
    //             })
    //     } else {
    //         var idSite: number = +localStorage.getItem("sites");
    //         this.http
    //             .get(`${this.url}/fournisseur/getWithFilter/${filter}`)
    //             .toPromise()
    //             .then((response) => {
    //                 for(let i=0; i<Object.keys(response).length; i++) {
    //                     this.recherche.push(response[i]);
    //                 }
    //             });
    //         this.http.get(`${this.url}/fournisseurPapiersAgree/getByIdSiteWithFilter/${idSite}/${filter}`)
    //             .toPromise()
    //             .then((response) => {
    //                 console.log(response);
    //                 for (let i = 0; i < Object.keys(response).length; i++) {
    //                     this.recherche.push(response[i]["idFournisseursPapiersAgreesNavigation"]);
    //                 }
    //             });
    //         this.http
    //             .get(`${this.url}/encreEtVernisAgree/getByIdSiteWithFilter/${idSite}/${filter}`)
    //             .toPromise()
    //             .then((response) => {
    //                 for (let i = 0; i < Object.keys(response).length; i++) {
    //                     this.recherche.push(response[i]["idEncresNavigation"]);
    //                 }
    //             });
    //         return this.http
    //             .get(`${this.url}/produitsDeNettoyageEtDesinfection/getByIdSiteWithFilter/${idSite}/${filter}`)
    //             .toPromise()
    //             .then((response) => {
    //                 console.log(response);
    //                 for (let i = 0; i < Object.keys(response).length; i++) {
    //                     this.recherche.push(response[i]["idProduitsNettoyageDesinfectionNavigation"]);
    //                 }
    //             });
    //     }
    // }
}