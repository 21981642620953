import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { CreerDemandeComponent } from "./creer-demande.component";
import { CreerDemandeRoute } from "./creer-demande.routing";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(CreerDemandeRoute)],
  declarations: [CreerDemandeComponent],
  exports: [CreerDemandeComponent, FormsModule],
})

export class CreerDemandeModule {
}
