import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ajoutComponent } from "./ajout.component";
import { ajoutRoute } from "./ajout.routing";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ajoutRoute)],
  declarations: [ajoutComponent],
  exports: [ajoutComponent, FormsModule],
})

export class ajoutModule {
}
