import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { FournisseursService } from 'src/app/components/_service/fournisseurs.service';
import swal from "sweetalert2";
import * as urlFromFile from '../../../../../url.json'
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/components/_service/auth.service';
import * as ExcelJS from 'exceljs'

@Component({
  selector: 'app-fournisseurs-component',
  templateUrl: './fournisseurs.component.html',
  styleUrls: ['./fournisseurs.component.css'],
})
export class FournisseursComponent implements OnInit {
  @ViewChild('myTable') table: DatatableComponent;

  data;
  url: string = urlFromFile['default']['url'];
  columns: Object[] = [];
  validite_name
  temp
  showArchives: boolean = false;
  entries: number = 10;
  selectedRow: string = 'any';
  activeRow: any;
  loading: boolean = false;
  columnsFromBdd: Object[] = [];
  tabToPrint = [];
  rows = [];
  currentImages = [];
  selectedColumn = 'all';
  excelData = [];

  constructor(private fournisseursService: FournisseursService, private route: Router, private http: HttpClient,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }

    if (!this.authService.canSee('fournisseurs_chimiques')) this.route.navigate(['/dashboard']);

    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
      for (let i = 0; i < this.data["data"].length; i++) {
        for (let j = 0; j < this.data.data[i]["whereToDisplay"].length; j++) {
          if (this.data.data[i]["whereToDisplay"][j]["nameOfTab"] === "fournisseurs") {
            if (this.data.data[i]["isChecked"] === true) {
              this.columns.push(this.data.data[i]);
            }
          }
        }
      }
      for (let k = 0; k < this.data.data.length; k++) {
        for (let l = 0; l < this.data.data[k]["belongsTo"].length; l++) {
          if (this.data.data[k]["belongsTo"][l]["table"] === "fournisseurs") {
            this.columnsFromBdd.push(this.data.data[k]);
          }
        }
      }

      // Modal de chargement qui se ferme après initialisation de tous les éléments //

      swal.fire({
        title: 'Chargement...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading()
        }
      });

      this.fournisseursService.initFournisseurs().then((response) => {
        this.temp = this.fournisseursService.fournisseurs;
        this.mapTables();
        swal.close()
      });
    }, (err) => {
      console.log(err);
    });
  }

  update(obj) {
    for (let [key, value] of Object.entries(this.activeRow)) {
      typeof value === 'string' && (value = value.replace(/<[^>]*>/g, ''));
      this.activeRow[key] = value;
    }
    this.fournisseursService.fournisseurs = this.activeRow;
    this.authService.tableName = obj;
    this.route.navigate(['modifier']);
  }

  delete() {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer l\'élément ?',
      text: "Cette action est irréversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value && this.authService.canSee('delete_fournisseurs_chimiques')) {
        this.fournisseursService.removeFournisseur(this.activeRow['idFournisseur']).then((value) => {
          swal.fire({
            title: 'Suppression réussie !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            this.fournisseursService
              .initFournisseurs()
              .then((value2) => {
                this.temp = this.fournisseursService.fournisseurs;
                this.tabToPrint = this.temp;
                this.mapTables();
              });
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Suppression annulée !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Affiche/Cache la modal de GED et vérifie la présence de document //

  ged() {
    if (document.getElementById("Fournisseur-" + this.activeRow['idFournisseur']).classList.contains("active")) {

    } else {
      this.loading = true;
      this.http.get(`${this.url}/file/fournisseurs/getFilesNameById/${this.activeRow["idFournisseur"]}`).toPromise().then((res) => {
        this.loading = false;
        this.currentImages = [];

        for (let i = 0; i < Object.keys(res).length; i++) {
          this.http.get(`${this.url}/file/fournisseurs/isImage/${res[i]["Fichier"]}`).toPromise().then((res2) => {
            var jsonData = {};
            jsonData["name"] = res[i]["Fichier"];
            jsonData["fileName"] = res[i]["Texte"];
            jsonData["isImage"] = res2;
            jsonData["creator"] = res[i]["Creator"];
            jsonData["DateCreation"] = res[i]["DateCreation"].split("T")[0] + " " + res[i]["DateCreation"].split("T")[1];
            this.currentImages.push(jsonData);
          });
        }
      });
    }
    document.getElementById("Fournisseur-" + this.activeRow['idFournisseur']).classList.toggle("active");
  }

  // Ajoute un document au GED de l'élément puis cache la modal //

  addGed(event) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir ajouter ce document ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value) {
        const file: File = event.target.files[0];
        const formData = new FormData();

        formData.append("thumbnail", file);

        this.http.post(`${this.url}/file/fournisseurs/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.activeRow["idFournisseur"],
            idGed: res,
            creator: this.authService.user.prenom
          }
          this.http.post(`${this.url}/file/fournisseurs/updateGED`, obj).toPromise().then((res2) => {
            this.ged();
          });
          swal.fire({
            title: 'Ajout réussi !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Ajout annulé !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Supprime le document séléctionné du GED de l'élément puis cache la modal //

  deleteGed(fileName) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value) {
        this.http.get(`${this.url}/file/fournisseurs/deleteFileByName/${fileName}`).toPromise().then((res) => {
          this.ged();
          swal.fire({
            title: 'Suppression réussie !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Suppression annulée !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Change la colonne de recherche //

  changeFilter($event) {
    this.selectedColumn = $event.target.value;
  }

  search($event) {
    if (!$event.target.value.length) {
      this.tabToPrint = this.rows;
      return;
    }

    const val = $event.target.value.toLowerCase();
    const selectedColumn = this.selectedColumn;
    const rows = this.rows;

    this.tabToPrint = rows.filter(row => {
      return selectedColumn !== 'all'
        ? row[selectedColumn].toString().toLowerCase().indexOf(val) !== -1
        : Object.values(row).some(value => value && value.toString().toLowerCase().indexOf(val) !== -1);
    }).map(row => {
      let newObj = {};
      for (let key in row) {
        if (row[key] != null) {
          const str = row[key].toString();
          const lowerCaseStr = str.toLowerCase();
          if (lowerCaseStr.indexOf(val) !== -1) {
            newObj[key] = str.replace(new RegExp(val, 'gi'), `<span class="highlight">${str.substr(lowerCaseStr.indexOf(val), val.length)}</span>`);
          } else {
            newObj[key] = str;
          }
        }
      }
      return newObj;
    });
  }

  mapTables() {
    let objTmp = {};
    this.tabToPrint = [];
    for (let i = 0; i < this.temp.length; i++) {
      objTmp = {};
      objTmp["idFournisseur"] = this.temp[i]['idFournisseur'];
      for (var col of this.columnsFromBdd) {
        objTmp[col["name"]] = this.temp[i][col["bddName"]];
      }
      this.tabToPrint.push(objTmp);
    }
    this.rows = this.tabToPrint;
  }

  onActivate(event) {
    const tabToMap = {};
    tabToMap["idFournisseur"] = event.row['idFournisseur'];
    tabToMap["archive"] = event.row['archive'];
    for (var col of this.columnsFromBdd) {
      tabToMap[col["bddName"]] = event.row[col["name"]];
    }
    this.activeRow = tabToMap;
  }

  changeEntries($event) {
    this.entries = $event.target.value;
  }

  ajout(obj) {
    this.authService.tableName = obj;
    this.route.navigate(['ajout']);
  }

  onRowClick(event) {
    const selectedElement: HTMLElement = event.target.closest('.datatable-body-row');
    const previouslySelectedElement: HTMLElement = this.table.element
      .querySelector('.datatable-body-row.row-selected');

    if (previouslySelectedElement) {
      previouslySelectedElement.style.backgroundColor = '';
      previouslySelectedElement.classList.remove('row-selected');
      if (previouslySelectedElement === selectedElement) {
        return;
      }
    }

    if (selectedElement) {
      selectedElement.classList.add('row-selected');
    }
  }


  exportToExcel() {
    this.excelData = this.rows;
    for (let i = 0; i < this.excelData.length; i++) {
      delete this.excelData[i]["archive"];
      delete this.excelData[i]["idFournisseur"];
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const columns = Object.keys(this.excelData[0]);
    worksheet.columns = columns.map((column) => ({
      header: column,
      key: column,
      width: 15,
    }));

    worksheet.columns[0] = { header: 'N°', key: 'idFournisseur', width: 5 };

    worksheet.addRows(this.excelData);

    worksheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'FournisseursProduitsChimiques.xlsx';
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }
}
