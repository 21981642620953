import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'
import { AuthService } from './auth.service';

@Injectable()
export class HuilesEtGraissesService {
  url: string = data['default']['url'];
  huilesEtGraisses;
  huileEtGraisse;

  listeFournisseurs;
  listePerimes;
  nombrePerimes;
  public lastId;
  paramUserId = 0;
  paramUserRole;
  isDemande = false;
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  initHuilesEtGraisses(id?: number) {

    this.http.get(`${this.url}/huilesEtGraisses/perimes`).toPromise().then((res) => {
      this.listePerimes = res;
      this.nombrePerimes = this.listePerimes.length;
    });

    this.huilesEtGraisses = [];
    this.huilesEtGraisses.splice(0, this.huilesEtGraisses.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);

    if (id == null) {
      this.paramUserRole = 0;
    } else if (id == 0) {
      this.paramUserRole = this.authService.userId;
    }

    if (this.authService.currentSite == null || +this.authService.currentSite["IdSite"] == 0) {
      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/huilesEtGraisses/${this.paramUserRole}`)
        .toPromise()
        .then((response) => {
          this.huilesEtGraisses = response;
        });
    } else {
      var idSite: number = this.authService.currentSite["IdSite"];

      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/huilesEtGraisses/getByIdSite/${idSite}`)
        .toPromise()
        .then((response) => {
          this.huilesEtGraisses = response;//on retourne directement un tableau de type HuilesEtGraisses
          /*for (let i = 0; i < Object.keys(response).length; i++) {
            this.huilesEtGraisses.push(response[i]["idHuileNavigation"]);
          }*/
        });
    }

  }

  getById(id) {
    this.huilesEtGraisses = [];
    this.huilesEtGraisses.splice(0, this.huilesEtGraisses.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    return this.http
      .get(`${this.url}/huilesEtGraisses/get/${id}`)
      .toPromise()
      .then((response) => {
        this.huilesEtGraisses = response
      });
  }

  updateHuilesEtGraisses(huileEtGraisse: JSON) {
    return this.http.put(`${this.url}/huilesEtGraisses/update/`, huileEtGraisse).toPromise();
  }

  removeHuilesEtGraisses(id: number) {
    return this.http.delete(`${this.url}/huilesEtGraisses/delete/${id}`).toPromise();
  }
}
