import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'
import { AuthService } from './auth.service';

@Injectable()
export class EncreEtVernisAgreesService {
  url: string = data['default']['url'];
  encreEtVernisAgrees;
  encreEtVernisAgree;
  encreEtVernisAgreesPerimes;
  listeFournisseurs;
  listePerimes;
  nombrePerimes;
  public lastId;
  paramUserRole;
  isDemande = false;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  initEncreEtVernisAgrees(id?: number) {

    this.http.get(`${this.url}/encreEtVernisAgree/perimes`).toPromise().then((res) => {
      this.listePerimes = res;
      this.nombrePerimes = this.listePerimes.length;
    });

    this.encreEtVernisAgrees = [];
    this.encreEtVernisAgrees.splice(0, this.encreEtVernisAgrees.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);

    if (id == null) {
      this.paramUserRole = 0;
    } else if (id == 0) {
      this.paramUserRole = this.authService.userId;
    }

    if (this.authService.currentSite == null || +this.authService.currentSite["IdSite"] == 0) {
      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/encreEtVernisAgree/${this.paramUserRole}`)
        .toPromise()
        .then((response) => {
          this.encreEtVernisAgrees = response;
        });

    } else {
      var idSite: number = this.authService.currentSite["IdSite"];

      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/encreEtVernisAgree/getByIdSite/${idSite}`)
        .toPromise()
        .then((response) => {
          /*for (let i = 0; i < Object.keys(response).length; i++) {
            this.encreEtVernisAgrees.push(response[i]["idEncresNavigation"]);
          }*/
          this.encreEtVernisAgrees = response;
        });
    }
  }

  getById(id) {
    this.encreEtVernisAgrees = [];
    this.encreEtVernisAgrees.splice(0, this.encreEtVernisAgrees.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    return this.http
      .get(`${this.url}/encreEtVernisAgree/get/${id}`)
      .toPromise()
      .then((response) => {
        this.encreEtVernisAgrees = response
      });
  }

  updateEncre(encreEtVernisAgree: JSON) {
    return this.http.put(`${this.url}/encreEtVernisAgree/update/`, encreEtVernisAgree).toPromise();
  }

  removePaper(id: number) {
    return this.http.delete(`${this.url}/encreEtVernisAgree/delete/${id}`).toPromise();
  }
}