import { Routes } from "@angular/router";
import { HuilesEtGraissesComponent } from "./huilesEtGraisses.component";

export const HuilesEtGraissesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: HuilesEtGraissesComponent
      }
    ]
  }
];
