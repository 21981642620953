import { Routes } from "@angular/router";
import { modifierComponent } from "./modifier.component";

export const modifierRoute: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: modifierComponent
      }
    ]
  }
];
