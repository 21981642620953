import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FournisseursPapiersAgreeComponent } from "./fournisseursPapiersAgree.component";
import { FournisseursPapiersAgreeRoutes } from "./fournisseursPapiersAgree.routing";
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule,
    RouterModule.forChild(FournisseursPapiersAgreeRoutes),
  ],
  declarations: [FournisseursPapiersAgreeComponent],
  exports: [FournisseursPapiersAgreeComponent, MatPaginatorModule, NgxDatatableModule, FormsModule],
})
export class FournisseursPapiersAgreeModule {

}