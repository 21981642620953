import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { modifierComponent } from "./modifier.component";
import { modifierRoute } from "./modifier.routing";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(modifierRoute), FormsModule],
  declarations: [modifierComponent],
  exports: [modifierComponent, FormsModule],
})

export class modifierModule {
}