import { Routes } from "@angular/router";
import { FournisseursPapiersAgreeComponent } from "./fournisseursPapiersAgree.component";

export const FournisseursPapiersAgreeRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: FournisseursPapiersAgreeComponent
      }
    ]
  }
];
