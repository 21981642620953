import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'

@Injectable()
export class PapierService {
  url: string = data['default']['url'];
  papier: JSON;

  papiers;

  constructor(private http: HttpClient) {
  }

  // Entrée : rien
  // Sortie : une Promise avec l'initialisation du tableau de papiers
  // méthode qui fait un appel au back, récupère tous les papiers, et les met tous dans le tableau de papiers

  initPapers() {
    this.papiers = [];
    this.papiers.splice(0, this.papiers.length);
    return this.http
      .get(`${this.url}/papier`)
      .toPromise()
      .then((response) => {
        this.papiers = response;
      });
  }

  // Entrée : rien
  // Sortie : une Promise avec la modification d'un papier en base
  // méthode faisant une requête PUT dans le back afin de mettre à jour un papier 

  updatePaper(papier: JSON) {
    return this.http.put(`${this.url}/papier/update/`, papier).toPromise();
  }

  // Entrée : rien
  // Sortie : une Promise avec l'ajout d'un papier en base
  // méthode faisant une requête POST dans le back afin d'ajouter un papier en base

  addPaper(papier: JSON) {
    return this.http.post(`${this.url}/papier`, papier).toPromise();
  }

  // Entrée : rien
  // Sortie : une Promise avec la suppression d'un papier en base
  // méthode faisant une requête DELETE dans le back afin de supprimer un papier en base 

  removePaper(id: number) {
    return this.http.delete(`${this.url}/papier/delete/${id}`).toPromise();
  }
}
