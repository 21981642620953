import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'

@Injectable()
export class FournisseursService {
  url: string = data['default']['url'];

  fournisseurs;
  fournisseur: JSON;

  constructor(private http: HttpClient) {
  }

  initFournisseurs() {
    this.fournisseurs = [];
    this.fournisseurs.splice(0, this.fournisseurs.length);
    return this.http
      .get(`${this.url}/fournisseur`)
      .toPromise()
      .then((response) => {
        this.fournisseurs = response;
      });
  }

  getById(id) {
    this.fournisseurs = []
    this.fournisseurs.splice(0, this.fournisseurs.length);
    return this.http
      .get(`${this.url}/fournisseur/${id}`)
      .toPromise()
      .then((response) => {
        this.fournisseurs = response;
      });
  }

  updateFournisseur(fournisseur: JSON) {
    return this.http.put(`${this.url}/fournisseur/update/`, fournisseur).toPromise();
  }

  addFournisseur(fournisseur: JSON) {
    return this.http.post(`${this.url}/fournisseur`, fournisseur).toPromise();
  }

  removeFournisseur(id: number) {
    return this.http.delete(`${this.url}/fournisseur/delete/${id}`).toPromise();
  }
}
