import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as data from '../../../url.json'
import { AuthService } from './auth.service';

@Injectable()
export class ProduitsDeNettoyageEtDesinfectionService {
  url: string = data['default']['url'];

  produitsDeNettoyageEtDesinfections;
  produitsDeNettoyageEtDesinfection;

  listeFournisseurs;

  listePerimes;
  nombrePerimes;
  paramUserRole;
  isDemande = false;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  // Entrée : rien
  // Sortie : une Promise avec l'initialisation du tableau de fournPapAgree
  // méthode qui fait un appel au back, récupère tous les fournPapAgrees, et les met tous dans le tableau de fournPapAgree

  initProduitsDeNettoyageEtDesinfection(id?: number) {

    if (id == null) {
      this.paramUserRole = 0;
    } else if (id == 0) {
      this.paramUserRole = this.authService.userId;
    }

    this.http.get(`${this.url}/produitsDeNettoyageEtDesinfection/perimes`).toPromise().then((res) => {
      this.listePerimes = res;
      this.nombrePerimes = this.listePerimes.length;
    });
    this.produitsDeNettoyageEtDesinfections = [];
    this.produitsDeNettoyageEtDesinfections.splice(0, this.produitsDeNettoyageEtDesinfections.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    if (this.authService.currentSite == null || +this.authService.currentSite["IdSite"] == 0) {
      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/produitsDeNettoyageEtDesinfection/${this.paramUserRole}`)
        .toPromise()
        .then((response) => {
          this.produitsDeNettoyageEtDesinfections = response;
        });

    } else {
      var idSite: number = this.authService.currentSite["IdSite"];

      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/produitsDeNettoyageEtDesinfection/getByIdSite/${idSite}`)
        .toPromise()
        .then((response) => {
          this.produitsDeNettoyageEtDesinfections = response;
        });
    }

  }

  getById(id) {
    this.produitsDeNettoyageEtDesinfections = [];
    this.produitsDeNettoyageEtDesinfections.splice(0, this.produitsDeNettoyageEtDesinfections.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    return this.http
      .get(`${this.url}/produitsDeNettoyageEtDesinfection/get/${id}`)
      .toPromise()
      .then((response) => {
        this.produitsDeNettoyageEtDesinfections = response
      });
  }

  // Entrée : rien
  // Sortie : une Promise avec la modification d'un fournPapAgree en base
  // méthode faisant une requête PUT dans le back afin de mettre à jour un fournPapAgree 

  updateProduitsDeNettoyageEtDesinfection(produitsDeNettoyageEtDesinfection: JSON) {
    return this.http.put(`${this.url}/produitsDeNettoyageEtDesinfection/update/`, produitsDeNettoyageEtDesinfection).toPromise();
  }

  // Entrée : rien
  // Sortie : une Promise avec l'ajout d'un fournPapAgree en base
  // méthode faisant une requête POST dans le back afin d'ajouter un fournPapAgree en base

  public lastId;

  // addProduitsDeNettoyageEtDesinfection(produitsDeNettoyageEtDesinfection: JSON) {
  //   return this.http.post(`${this.url}/produitsDeNettoyageEtDesinfection`, produitsDeNettoyageEtDesinfection).toPromise().then((res) => {
  //     this.lastId = res;
  //   });
  // }

  // Entrée : rien
  // Sortie : une Promise avec la suppression d'un fournPapAgree en base
  // méthode faisant une requête DELETE dans le back afin de supprimer un fournPapAgree en base 

  removeProduitsDeNettoyageEtDesinfection(id: number) {
    return this.http.delete(`${this.url}/produitsDeNettoyageEtDesinfection/delete/${id}`).toPromise();
  }
}
