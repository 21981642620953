import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/components/_service/auth.service";
import swal from "sweetalert2";
import { NgForm } from '@angular/forms';
import * as urlFromFile from '../../../../url.json'
import { Router } from '@angular/router';

@Component({
  selector: "app-parameters",
  templateUrl: "parameters.component.html"
})
export class ParametersComponent implements OnInit {

  url: string = urlFromFile['default']['url'];

  // pour les en-têtes de tableaux

  columnsOfTable = [];

  currentObject: Object[] = [];

  allTables = [
    {
      name: "Liste fournisseurs produits chimiques",
      bddName: "fournisseurs"
    },
    {
      name: "Liste fournisseurs papier",
      bddName: "fournisseursPapier"
    },
    {
      name: "Papiers agréés",
      bddName: "fournisseursPapiersAgrees"
    },
    {
      name: "Colles et adjuvants",
      bddName: "CollesAdjuvants"
    },
    {
      name: "Produits de nettoyage et désinfection",
      bddName: "produitsDeNettoyageEtDesinfection"
    },
    {
      name: "Encres et vernis agréés",
      bddName: "encreEtVernisAgrees"
    },
    {
      name: "Huiles et graisses",
      bddName: "huilesEtGraisses"
    }
  ];

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  data;
  currentTable: Object = {};
  boolProduit: boolean = false;
  loading: boolean = false;
  allSites;
  currentSite;
  currentObjectName = 0;
  nbChoices = 2;
  numbers;
  currentVal = [];
  temp;
  showYears;
  showSites;
  parameters;
  tables = []

  constructor(private http: HttpClient, private authService: AuthService, private route: Router) { }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }

    if (!this.authService.canSee("parameters")) {
      this.route.navigate(["/dashboard"]);
    }

    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    });
    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
      swal.close();
    });
    this.http.get(`${this.url}/sites/0`).toPromise().then((res) => {
      this.allSites = res;
      this.loading = false;
    });

    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.parameters = res["parameters"][0];
    });
  }

  // Il faut prier pour ne jamais avoir à debuger ça

  show(obj) {
    this.showYears = false;
    this.showSites = false;
    this.temp = obj;
    this.currentTable = obj["name"];
    this.columnsOfTable = [];
    this.currentObject = [];
    let obj1 = [];
    let obj2;
    let obj3;
    let idx = -1;
    for (let k = 0; k < this.data.data.length; k++) {
      obj2 = {};
      obj3 = {};
      for (let l = 0; l < this.data.data[k]["belongsTo"].length; l++) {
        if (this.data.data[k]["belongsTo"][l]["table"] === obj["bddName"]) {
          obj1.push(this.data.data[k]);
          idx++;
        }
      }
      for (let m = 0; m < this.data.data[k]["whereToDisplay"].length; m++) {
        if (this.data.data[k]["whereToDisplay"][m]["nameOfTab"] === obj["bddName"]) {
          obj3 = { ...obj1[idx], ...obj2 };
          if (obj3["bddName"] != undefined) {
            obj2 = { isChecked: this.data.data[k]["isChecked"] };
            this.columnsOfTable.push(obj3)
          }
        }
      }
    }
    let bool = false;
    for (let n = 0; n < obj1.length; n++) {
      bool = false;
      for (let o = 0; o < this.columnsOfTable.length; o++) {
        if (obj1[n]["name"] === this.columnsOfTable[o]["name"]) {
          bool = true;
        }
      }
      if (!bool) {
        obj2 = { isChecked: false };
        obj3 = { ...obj1[n], ...obj2 };
        this.columnsOfTable.push(obj3)
      }
    }
    this.currentObject = JSON.parse(JSON.stringify(this.columnsOfTable));
  }

  // Affiche autre chose qu'une table, pour l'instant uniquement les années //

  showOther(obj) {
    if (obj === "years") {
      this.columnsOfTable = [];
      this.showSites = false;
      this.showYears = true;
      for (let i = 0; i < this.parameters["tables"].length; i++) {
        this.tables[i] = {
          name: this.parameters["tables"][i]["name"],
          bddName: this.parameters["tables"][i]["bddName"],
          year: this.parameters["tables"][i]["year"]
        };
      }
    }
    if (obj === "sites") {
      this.columnsOfTable = [];
      this.showSites = true;
      this.showYears = false;
    }
  }

  // Affiche la modal des années //

  showYear(obj) {
    document.getElementById("year-" + obj).classList.toggle("active");
  }

  // Affiche la modal des sites //

  showSite(obj) {
    document.getElementById("site-" + obj).classList.toggle("active");
  }

  // Sauvegarde les années //

  saveSite(obj) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier le site ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.http.post(`${this.url}/sites/update`, obj).toPromise().then((res) => { });
        swal.fire({
          title: 'Le site a été modifiée',
          icon: 'success',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          this.showSite(obj);
          this.showSites = false;
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Le site n\'a pas été modifiée',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Sauvegarde les années //

  saveYear(obj) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier l\'année de la table ' + obj + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        for (let i = 0; i < this.parameters["tables"].length; i++) {
          if (this.parameters["tables"][i]["bddName"] === obj) {
            this.parameters["tables"][i]["year"] = this.tables[i]["year"];
            this.data["parameters"][0]["tables"][i]["year"] = this.tables[i]["year"];
            this.http.post(`${this.url}/conf/post`, JSON.stringify(this.data), this.options).toPromise().then((res) => { });
          }
        }
        swal.fire({
          title: 'L\'année a été modifiée',
          icon: 'success',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          this.showYear(obj);
          this.showYears = false;
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'L\'année n\'a pas été modifiée',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Affiche la modal de l'élément sur lequel on clique

  showChoices(currentObject) {
    this.numbers = [];
    var modal = document.getElementById(currentObject["bddName"]);
    modal.classList.toggle("active");
    this.currentObjectName = currentObject["name"];
    this.nbChoices = 2;
    if (currentObject["valeursPredefinies"] === true) {
      this.numbers = Array(currentObject["nbValPre"]).fill(0).map((x, i) => i);
    }
    this.currentVal = [];
    for (let i = 0; i < currentObject["nbValPre"]; i++) {
      this.currentVal[i] = currentObject["tabVal"][i];
    }
  }

  addOption(currentObject) {
    if (currentObject["valeursPredefinies"] === true) {
      currentObject["nbValPre"]++;
      currentObject["tabVal"].push("");
    } else {

    }
  }

  removeOption(currentObject, obj) {
    if (currentObject["nbValPre"] > 2) {
      currentObject["tabVal"].splice(obj, 1);
      currentObject["nbValPre"]--;
    } else {
      alert("Il vous faut au minimum 2 options");
    }
  }

  // Enregistre les valeurs entrées et les envoie dans le back (conf.json)

  save(currentObject) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier l\'élément ?',
      showCancelButton: true,
      confirmButtonText: `Oui`,
      cancelButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        for (let i = 0; i < this.data["data"].length; i++) {
          if (currentObject["bddName"] === this.data["data"][i]["bddName"]) {
            this.data["data"][i]["name"] = currentObject["name"];
            this.data["data"][i]["isChecked"] = currentObject["isChecked"];
            console.log(currentObject["required"])
            this.data["data"][i]["required"] = currentObject["required"];
            this.data["data"][i]["valeursPredefinies"] = currentObject["valeursPredefinies"];
            this.data["data"][i]["nbValPre"] = currentObject["nbValPre"];
            if (currentObject["valeursPredefinies"] === false) {
              this.data["data"][i]["nbValPre"] = 0;
              this.data["data"][i]["tabVal"] =
                [
                  false,
                  false,
                ];
            }
          }
        }
        this.http.post(`${this.url}/conf/post`, JSON.stringify(this.data), this.options).toPromise().then((res) => {
        });
        this.showChoices(currentObject);
        this.show(this.temp);
        swal.fire({
          title: 'L\'élément a bien été modifié',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        this.show(this.temp);
      }
    });
  }

  // Vérification du nombre de champs et redirection vers la création

  next(currentObject) {
    if (currentObject["nbValPre"] >= 2) {
      this.numbers = Array(currentObject["nbValPre"]).fill(0).map((x, i) => i);
      document.getElementById(currentObject["bddName"]).className = "currentObjectModal active next";
    } else {
      alert("Veuillez rentrer une valeur supérieure ou égale à 2");
    }
  }

  // Création de valeurs prédéfinies dans le back à partir des données du formulaire

  async onSubmit(form: NgForm, currentObject) {
    currentObject["tabVal"] = form.value;
    let tab = [];
    for (let i = 0; i < currentObject["nbValPre"]; i++) {
      tab[i] = form.value["option-" + (i + 1)];
    }
    // var toto = JSON.stringify(currentObject["tabVal"]).replace('{', '').replace('}', '').split(',');
    for (let i = 0; i < this.data["data"].length; i++) {
      if (currentObject["bddName"] === this.data["data"][i]["bddName"]) {
        this.data["data"][i]["tabVal"] = tab;
        this.data["data"][i]["nbValPre"] = currentObject["nbValPre"];
        this.data["data"][i]["valeursPredefinies"] = currentObject["valeursPredefinies"];
      }
    }
    this.http.post(`${this.url}/conf/post`, JSON.stringify(this.data), this.options).toPromise().then((res) => { });
    this.save(currentObject);
  }

  // Modification de valeurs prédéfinies dans le back à partir des données du formulaire

  async onModif(form: NgForm, currentObject) {
    currentObject["tabVal"] = form.value;
    let tab = [];
    for (let i = 0; i < currentObject["nbValPre"]; i++) {
      tab[i] = form.value["option-" + (i + 1)];
    }
    for (let i = 0; i < this.data["data"].length; i++) {
      if (currentObject["bddName"] === this.data["data"][i]["bddName"]) {
        this.data["data"][i]["tabVal"] = tab;
        this.data["data"][i]["nbValPre"] = currentObject["nbValPre"];
        this.data["data"][i]["valeursPredefinies"] = currentObject["valeursPredefinies"];
      }
    }
    this.http.post(`${this.url}/conf/post`, JSON.stringify(this.data), this.options).toPromise().then((res) => { });
    this.save(currentObject);
  }


  changeSite(event) {
    this.authService.currentSite = this.currentSite;
  }
}
