import { Routes } from "@angular/router";
import { CreerDemandeComponent } from "./creer-demande.component";

export const CreerDemandeRoute: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: CreerDemandeComponent
      }
    ]
  }
];