import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { collesAdjuvantsComponent } from "./collesAdjuvants.component";
import { collesAdjuvantsRoutes } from "./collesAdjuvants.routing";
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(collesAdjuvantsRoutes),],
  declarations: [collesAdjuvantsComponent],
  exports: [collesAdjuvantsComponent, MatPaginatorModule, NgxDatatableModule, FormsModule],
})
export class collesAdjuvantsModule {

}