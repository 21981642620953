import { getNumberOfCurrencyDigits } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'
import { AuthService } from './auth.service';

@Injectable()
export class CollesAdjuvantsService {
  url: string = data['default']['url'];

  collesAdjuvants;
  colleAdjuvant;

  listeFournisseurs;

  isDemande = false;

  listePerimes;
  nombrePerimes;
  paramUserRole;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  // Entrée : rien
  // Sortie : une Promise avec l'initialisation du tableau de colles
  // méthode qui fait un appel au back, récupère tous les colles, et les met tous dans le tableau de colles

  initCollesAdjuvants(id?: number) {
    if (id == null) {
      this.paramUserRole = 0;
    } else if (id == 0) {
      this.paramUserRole = this.authService.userId;
    }

    this.http.get(`${this.url}/CollesEtAdjuvants/perimes`).toPromise().then((res) => {
      this.listePerimes = res;
      this.nombrePerimes = this.listePerimes.length;
    });
    this.collesAdjuvants = [];
    this.collesAdjuvants.splice(0, this.collesAdjuvants.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    if (this.authService.currentSite == null || +this.authService.currentSite["IdSite"] == 0) {
      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/CollesEtAdjuvants/${this.paramUserRole}`)
        .toPromise()
        .then((response) => {
          this.collesAdjuvants = response;
        });

    } else {
      var idSite: number = this.authService.currentSite["IdSite"];

      this.http
        .get(`${this.url}/fournisseur`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/CollesEtAdjuvants/getByIdSite/${idSite}`)
        .toPromise()
        .then((response) => {
          /*for (let i = 0; i < Object.keys(response).length; i++) {
            this.collesAdjuvants.push(response[i]["idCollesNavigation"]);
          }*/
          this.collesAdjuvants = response;
        });
    }
  }

  getById(id) {
    this.collesAdjuvants = []
    this.collesAdjuvants.splice(0, this.collesAdjuvants.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    return this.http
      .get(`${this.url}/CollesEtAdjuvants/get/${id}`)
      .toPromise()
      .then((response) => {
        this.collesAdjuvants = response;
      });
  }

  // Entrée : rien
  // Sortie : une Promise avec la modification d'une colle en base
  // méthode faisant une requête PUT dans le back afin de mettre à jour une colle

  updateColle(colleAdjuvant: JSON) {
    return this.http.put(`${this.url}/CollesEtAdjuvants/update/`, colleAdjuvant).toPromise();
  }

  // Entrée : rien
  // Sortie : une Promise avec l'ajout d'une colle en base
  // méthode faisant une requête POST dans le back afin d'ajouter une colle en base

  public lastId;

  // addColle(colleAdjuvant: JSON) {
  //   return this.http.post(`${this.url}/CollesEtAdjuvants`, colleAdjuvant).toPromise();
  // }

  // Entrée : rien
  // Sortie : une Promise avec la suppression d'une colle en base
  // méthode faisant une requête DELETE dans le back afin de supprimer une colle en base 

  removeColle(id: number) {
    return this.http.delete(`${this.url}/CollesEtAdjuvants/delete/${id}`).toPromise();
  }
}
