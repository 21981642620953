import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { FournisseursComponent } from "./fournisseurs.component";
import { FournisseursRoutes } from "./fournisseurs.routing";

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';

import { NgxDatatableModule } from "@swimlane/ngx-datatable";

@NgModule({
  imports: [CommonModule, RouterModule.forChild(FournisseursRoutes)],
  declarations: [FournisseursComponent],
  exports: [FournisseursComponent, MatFormFieldModule, MatSelectModule, MatPaginatorModule, MatInputModule, MatTableModule, NgxDatatableModule],
})
export class FournisseursModule {
  
}