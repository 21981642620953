import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/components/_service/auth.service";
import swal from "sweetalert2";
import * as urlFromFile from '../../../../url.json'
import { Router } from '@angular/router';

@Component({
  selector: "app-profil",
  templateUrl: "profil.component.html"
})

export class ProfilComponent implements OnInit {

  url: string = urlFromFile['default']['url'];
  data;
  allSites;
  userInfos;
  constructor(private http: HttpClient, private authService: AuthService, private route: Router) { }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }

    swal.fire({
      title: 'Chargement...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading()
      }
    });

    this.fetchSites();
    this.fetchConf();
    this.getUserInfos();
    swal.close();
  }

  fetchSites() {
    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
    });
  }

  fetchConf() {
    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
    });
  }

  getUserInfos() {
    this.http.get(`${this.url}/user/${this.authService.userId}`).toPromise().then((res) => {
      this.userInfos = res;
      console.log(this.data);
    });
  }
}
