import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";

import { ProfilComponent } from "./profil.component";

import { ProfilRoutes } from "./profil.routing";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ProfilRoutes),
    ModalModule.forRoot()
  ],
  declarations: [ProfilComponent],
  exports: [ProfilComponent]
})
export class ProfilModule { }
