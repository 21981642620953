import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

/**
 * TEST IMPORT
 */
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from './angular-material.module';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { PapierService } from "./components/_service/papier.service";
import { AuthGuard } from "./components/_service/authGuard.service";
import { AuthService } from "./components/_service/auth.service";
import { FournisseursPapiersAgreeModule } from "./pages/examples/Table/fournisseursPapiersAgree/fournisseursPapiersAgree.module";
import { FournisseursPapiersAgreeService } from "./components/_service/fournisseursPapiersAgree.service"
import { FournisseursService } from "./components/_service/fournisseurs.service";
import { FournisseursModule } from "./pages/examples/Table/fournisseurs/fournisseurs.module";
import { EncreEtVernisAgreesModule } from "./pages/examples/Table/encreEtVernisAgrees/encreEtVernisAgrees.module";
import { EncreEtVernisAgreesService } from "./components/_service/encreEtVernisAgrees.service";
import { collesAdjuvantsModule } from "./pages/examples/Table/collesAdjuvants/collesAdjuvants.module"
import { CollesAdjuvantsService } from "./components/_service/collesAdjuvants.service"
import { ProduitsDeNettoyageEtDesinfectionService } from "./components/_service/produitsDeNettoyageEtDesinfection.service";
import { ProduitsDeNettoyageEtDesinfectionModule } from "./pages/examples/Table/produitsDeNettoyageEtDesinfection/produitsDeNettoyageEtDesinfection.module";
import { HuilesEtGraissesModule } from "./pages/examples/Table/huilesEtGraisses/huilesEtGraisses.module";
import { ParametersModule } from "./pages/examples/parameters/parameters.module";
import { RechercheService } from "./components/_service/recherche.service";
import { DashboardModule } from "./pages/examples/dashboard/dashboard.module";
import { AdminParametersModule } from "./pages/examples/admin-parameters/admin-parameters.module";
import { ajoutModule } from "./pages/examples/ajout/ajout.module";
import { modifierModule } from "./pages/examples/modifier/modifier.module";
import { ProfilModule } from "./pages/examples/profil/profil.module";
import { HuilesEtGraissesService } from "./components/_service/huilesEtGraisses.service";
import { FournisseursPapierService } from "./components/_service/fournisseursPapier.service";
import { FournisseursPapierModule } from "./pages/examples/Table/fournisseursPapier/fournisseursPapier.module";
import { CreerDemandeModule } from './pages/examples/creer-demande/creer-demande.module';
import { DemandesModule } from "./pages/examples/demandes/demandes.module";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RtlLayoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FournisseursPapiersAgreeModule,
    ajoutModule,
    modifierModule,
    ProfilModule,
    FournisseursModule,
    FournisseursPapierModule,
    CreerDemandeModule,
    DemandesModule,
    EncreEtVernisAgreesModule,
    ProduitsDeNettoyageEtDesinfectionModule,
    HuilesEtGraissesModule,
    collesAdjuvantsModule,
    ParametersModule,
    ParametersModule,
    DashboardModule,
    AdminParametersModule,
  ],
  providers: [PapierService, AuthService, AuthGuard, FournisseursPapiersAgreeService, FournisseursService,
    EncreEtVernisAgreesService, ProduitsDeNettoyageEtDesinfectionService, RechercheService, CollesAdjuvantsService, HuilesEtGraissesService, FournisseursPapierService],
  bootstrap: [AppComponent]
})
export class AppModule { }
