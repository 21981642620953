import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_service/auth.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
  }

  redirect(value, index: number) {
    window.location.replace("#/" + value)
  }
}