import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-rtl-sidebar",
  templateUrl: "./rtl-sidebar.component.html",
  styleUrls: ["./rtl-sidebar.component.scss"]
})
export class RtlSidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
  }
}
