import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/components/_service/auth.service";
import swal from "sweetalert2";
import * as urlFromFile from '../../../../url.json'

@Component({
  selector: "app-parameters",
  templateUrl: "admin-parameters.component.html",
  styleUrls: ['./admin-parameters.component.css'],

})
export class AdminParametersComponent implements OnInit {

  url: string = urlFromFile['default']['url'];

  showUsers: boolean = false;
  showUser: boolean = false;
  showAddUser: boolean = false;
  allUsers: any = [];
  username: string = '';
  currentUser: any = {};
  passwordConfirm: string = '';
  allSites: any = [];
  sitesOfUser: any = [];
  sitesToAdd: any = [];
  sitesToRemove: any = [];
  nbSites: number = 0;
  checkAll = false;
  allRoles: any = [];
  currentUserPassword;
  changePassword: boolean = false;
  newPassword;
  newPasswordConfirm;

  constructor(private http: HttpClient, private authService: AuthService, private route: Router) { }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
    this.http.get(`${this.url}/getRoles`).toPromise().then((res) => {
      this.allRoles = res;
    });
    if (!this.authService.canSee("admin_parameters")) {
      this.route.navigate(['/dashboard'])
    } else if (this.authService.userRole == "admin") {
      this.http.get(`${this.url}/sites/0`).toPromise().then((res) => {
        this.allSites = res;
      });
    }
  }

  users() {
    this.http.get(`${this.url}/user`).toPromise().then((res) => {
      this.showUsers = true;
      this.showUser = false;
      this.showAddUser = false;
      this.changePassword = false;
      this.allUsers = res;
    });
  }

  user(obj) {
    this.showAddUser = false;
    this.showUsers = false;
    this.changePassword = false;
    this.showUser = true;
    this.currentUser = {};
    this.sitesOfUser = [];
    this.sitesToAdd = [];
    this.sitesToRemove = [];
    this.http.get(`${this.url}/user/${obj}`).toPromise().then((res) => {
      this.currentUser = res;
    });
    this.http.get(`${this.url}/user/getSitesById/${obj}`).toPromise().then((res) => {
      for (let i = 0; i < Object.keys(res).length; i++) {
        this.sitesOfUser.push(res[i]["IdSite"]);
        this.nbSites++;
      }
    });
  }

  updateUser() {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir modifier cet utilisateur ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.value) {
        let obj =
        {
          userId: this.currentUser.userId,
          Username: this.currentUser.username,
          Role: this.currentUser.role,
          Nom: this.currentUser.nom,
          Prenom: this.currentUser.prenom,
          Email: this.currentUser.email,
        }
        this.http.post(`${this.url}/user/update`, obj).toPromise().then((res) => {
          for (let i = 0; i < this.sitesToAdd.length; i++) {
            if (this.sitesToAdd.length > 0) {
              var obj = {
                "id": this.currentUser.userId,
                "idSite": this.sitesToAdd[i]
              }
              this.http.post(`${this.url}/user/addUserToSite`, obj).toPromise().then((res) => {
              });
            }
          }
          for (let i = 0; i < this.sitesToRemove.length; i++) {
            if (this.sitesToRemove.length > 0) {
              var obj = {
                "id": this.currentUser.userId,
                "idSite": this.sitesToRemove[i]
              }
              this.http.post(`${this.url}/user/removeUserFromSite`, obj).toPromise().then((res) => {
              });
            }
          }
          swal.fire({
            title: 'Utilisateur modifié',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          });
          this.users();
          this.http.get(`${this.url}/user`).toPromise().then((res) => {
            this.allUsers = res;
          });
          this.sitesOfUser = [];
          this.sitesToAdd = [];
          this.sitesToRemove = [];
          this.nbSites = 0;
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire(
          'Annulé',
          'L\'utilisateur n\'a pas été modifié',
          'error'
        )
      }
    });
  }

  ajouter() {
    this.passwordConfirm = '';
    this.currentUser = {};
    this.showAddUser = true;
    this.showUser = false;
    this.showUsers = false;
    this.changePassword = false;
  }

  addUser() {
    if (this.currentUser.password != this.passwordConfirm) {
      swal.fire({
        title: 'Les mots de passe ne correspondent pas',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } else {
      if (this.currentUser.username != null && this.currentUser.password != null && this.currentUser.role != null) {
        swal.fire({
          title: 'Êtes-vous sûr de vouloir ajouter cet utilisateur ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then((result) => {
          if (result.value) {
            let obj =
            {
              Username: this.currentUser.username,
              Password: this.currentUser.password,
              Role: this.currentUser.role,
              Nom: this.currentUser.nom,
              Prenom: this.currentUser.prenom,
              Email: this.currentUser.email,
              ImageUrl: '../../../assets/img/user.png'
            }
            this.http.post(`${this.url}/user`, obj).toPromise().then((res) => {
              swal.fire({
                title: 'Utilisateur ajouté',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              }).then((result) => {
                this.users();
              });
            });
          } else if (result.dismiss === swal.DismissReason.cancel) {
            swal.fire({
              title: 'Annulé',
              text: 'L\'utilisateur n\'a pas été ajouté',
              icon: 'error',
              confirmButtonText: 'OK'
            }).then((result) => {
              this.users();
            });
          }
        });
      } else {
        swal.fire({
          title: 'Veuillez remplir tous les champs',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    }
  }

  deleteUser(obj) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.value) {
        this.http.delete(`${this.url}/user/delete/${obj}`).toPromise().then((res) => {
          swal.fire({
            title: 'Utilisateur supprimé',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          }).then((result) => {
            this.users();
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Annulé',
          text: 'L\'utilisateur n\'a pas été supprimé',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.users();
        });
      }
    });
  }

  checkAllSites() {
    if (this.checkAll) {
      this.sitesToAdd = [];
      this.sitesToRemove = [];
      this.sitesOfUser = [];
      this.nbSites = 0;
      for (let i = 0; i < this.allSites.length; i++) {
        this.sitesToRemove.push(this.allSites[i]["IdSite"]);
      }
      this.checkAll = false;
    } else if (!this.checkAll) {
      this.sitesToAdd = [];
      this.sitesToRemove = [];
      this.sitesOfUser = [];
      this.nbSites = 0;
      for (let i = 0; i < this.allSites.length; i++) {
        this.sitesToAdd.push(this.allSites[i]["IdSite"]);
        this.nbSites++;
      }
      this.checkAll = true;
    }
  }

  checkSite(obj, event) {
    if (event.target.checked) {
      this.sitesToAdd.push(obj);
      this.sitesToRemove = this.sitesToRemove.filter((item) => item != obj);
      this.nbSites++;
    } else {
      this.sitesToAdd = this.sitesToAdd.filter((item) => item != obj);
      this.sitesToRemove.push(obj);
      this.nbSites--;
    }
  }

  changePw() {
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.changePassword = !this.changePassword;
  }

  postNewPassword(userId) {
    if (this.newPassword != this.newPasswordConfirm) {
      swal.fire({
        title: 'Les mots de passe ne correspondent pas',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    } else {
      let obj =
      {
        userId: userId,
        Username: this.currentUser.username,
        Password: this.newPassword,
        Role: this.currentUser.role,
        Nom: this.currentUser.nom,
        Prenom: this.currentUser.prenom,
        Email: this.currentUser.email,
        ImageUrl: '../../../assets/img/user.png'
      }
      this.http.post(`${this.url}/user/update_password`, obj).toPromise().then((res) => {
        swal.fire({
          title: 'Mot de passe modifié',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        });
        this.users();
      });
    }
  }
}