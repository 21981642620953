import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'
import { AuthService } from './auth.service';

@Injectable()
export class FournisseursPapiersAgreeService {
  url: string = data['default']['url'];

  fournisseurPapiersAgrees;
  fournisseurPapierAgree: JSON;

  idFournisseurPapier;
  listeFournisseurs;
  paramUserRole;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  initFournisseursPapiersAgreeService(id?: number) {
    if (id == null) {
      this.paramUserRole = 0;
    } else if (id == 0) {
      this.paramUserRole = this.authService.userId;
    }

    this.fournisseurPapiersAgrees = [];
    this.fournisseurPapiersAgrees.splice(0, this.fournisseurPapiersAgrees.length);

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);

    if (this.authService.currentSite == null || +this.authService.currentSite["IdSite"] == 0) {
      this.http
        .get(`${this.url}/fournisseursPapier`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/fournisseurPapiersAgree/${this.paramUserRole}`)
        .toPromise()
        .then((response2) => {
          this.fournisseurPapiersAgrees = response2;
        });

    } else {
      var idSite: number = +this.authService.currentSite["IdSite"];

      this.http
        .get(`${this.url}/fournisseursPapier`)
        .toPromise()
        .then((response2) => {
          this.listeFournisseurs = response2;
        });
      return this.http
        .get(`${this.url}/fournisseurPapiersAgree/getByIdSite/${idSite}`)
        .toPromise()
        .then((response) => {
          this.fournisseurPapiersAgrees = response;
        });
    }

  }

  getById(id) {
    this.fournisseurPapiersAgrees = []
    this.fournisseurPapiersAgrees.splice(0, this.fournisseurPapiersAgrees.length)

    this.listeFournisseurs = [];
    this.listeFournisseurs.splice(0, this.listeFournisseurs.length);
    return this.http
      .get(`${this.url}/fournisseurPapiersAgree/get/${id}`)
      .toPromise()
      .then((response) => {
        this.fournisseurPapiersAgrees = response;
      });
  }

  updatePaper(fournPapAgree: JSON) {
    return this.http.put(`${this.url}/fournisseurPapiersAgree/update/`, fournPapAgree).toPromise();
  }

  removePaper(id: number) {
    return this.http.delete(`${this.url}/fournisseurPapiersAgree/delete/${id}`).toPromise();
  }
}
