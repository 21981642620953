import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";

import { AdminParametersComponent } from "./admin-parameters.component";

import { AdminParametersRoutes } from "./admin-parameters.routing";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(AdminParametersRoutes),
    ModalModule.forRoot()
  ],
  declarations: [AdminParametersComponent],
  exports: [AdminParametersComponent]
})
export class AdminParametersModule {}
