import { Routes } from "@angular/router";

import { ParametersComponent } from "./parameters.component";

export const ParametersRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: ParametersComponent
      }
    ]
  }
];
