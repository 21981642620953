import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ProduitsDeNettoyageEtDesinfectionComponent } from "./produitsDeNettoyageEtDesinfection.component";
import { ProduitsDeNettoyageEtDesinfectionRoutes } from "./produitsDeNettoyageEtDesinfection.routing";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ProduitsDeNettoyageEtDesinfectionRoutes),
  ],
  declarations: [ProduitsDeNettoyageEtDesinfectionComponent],
  exports: [ProduitsDeNettoyageEtDesinfectionComponent, MatPaginatorModule, MatInputModule, NgxDatatableModule, FormsModule],
})
export class ProduitsDeNettoyageEtDesinfectionModule {
}