import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";
import * as urlFromFile from '../../../url.json'
import { AuthService } from "../_service/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})

export class NavbarComponent implements OnInit {
  url: string = urlFromFile['default']['url'];
  allSites;
  currentSite;

  public static nomSite = "Tous les sites";

  get staticSite() {
    return NavbarComponent.nomSite;
  }

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }
  }

  showAside() {
    var aside = document.querySelector(".aside");
    aside.classList.toggle("active");
  }

  showUserModal() {
    var modal = document.getElementById("modal-user");
    modal.classList.toggle("active");
  }


  logout() {
    swal.fire({
      title: "Êtes-vous sûr de vouloir vous déconnecter ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
      }
    });
  }

  goToPdf(obj) {
    if (obj == 'user') {
      window.open("/documentation/Guide-utilisation-foodexpert.pdf", "_blank");
    } else if (obj == 'admin') {
      window.open("/documentation/Guide-administration-foodexpert.pdf", "_blank");
    } else {
      alert('Une erreur est survenue')
    }
  }
}
