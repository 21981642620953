import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as data from '../../../url.json'

@Injectable()
export class AuthGuard implements CanActivate {

  url: string = data['default']['url'];

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(private authService: AuthService, private router: Router, private http: HttpClient) { }

  canActivate(): boolean | Promise<boolean> {
    if (sessionStorage.length > 0) {
      return this.http
        .post(`${this.url}/authent`, sessionStorage.getItem('token'), this.options)
        .toPromise()
        .then((response) => {
          if (response == true) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        });
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}