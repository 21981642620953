import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from '../../../url.json'

@Injectable()
export class FournisseursPapierService {
  url: string = data['default']['url'];

  fournisseursPapier;
  fournisseurPapier: JSON;

  constructor(private http: HttpClient) {
  }

  initFournisseursPapier() {
    this.fournisseursPapier = [];
    this.fournisseursPapier.splice(0, this.fournisseursPapier.length);
    return this.http
      .get(`${this.url}/fournisseursPapier`)
      .toPromise()
      .then((response) => {
        this.fournisseursPapier = response;
      });
  }

  getById(id) {
    this.fournisseursPapier = []
    this.fournisseursPapier.splice(0, this.fournisseursPapier.length);
    return this.http
      .get(`${this.url}/fournisseursPapier/${id}`)
      .toPromise()
      .then((response) => {
        this.fournisseursPapier = response;
      });
  }

  updateFournisseurPapier(fournisseurPapier: JSON) {
    return this.http.put(`${this.url}/fournisseursPapier/update/`, fournisseurPapier).toPromise();
  }

  addFournisseurPapier(fournisseurPapier: JSON) {
    return this.http.post(`${this.url}/fournisseursPapier`, fournisseurPapier).toPromise();
  }

  removeFournisseurPapier(id: number) {
    return this.http.delete(`${this.url}/fournisseursPapier/delete/${id}`).toPromise();
  }
}
