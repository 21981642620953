import { Component, OnInit, ViewChild, } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import swal from "sweetalert2";
import { HuilesEtGraissesService } from 'src/app/components/_service/huilesEtGraisses.service';
import { HttpClient } from '@angular/common/http';
import * as urlFromFile from '../../../../../url.json'
import { AuthService } from 'src/app/components/_service/auth.service';
import { NgForm } from '@angular/forms';
import { FournisseursService } from 'src/app/components/_service/fournisseurs.service';
import * as ExcelJS from 'exceljs';

@Component({
  selector: 'app-huilesEtGraisses-component',
  templateUrl: './huilesEtGraisses.component.html',
  styleUrls: ['./huilesEtGraisses.component.css'],
})

export class HuilesEtGraissesComponent implements OnInit {
  @ViewChild('myTable') table: DatatableComponent;

  data;
  url: string = urlFromFile['default']['url'];
  columns: Object[] = [];
  validite_name
  temp
  showArchives: boolean = false;
  entries: number = 10;
  selectedRow: string = 'any';
  activeRow: any;
  loading: boolean = false;
  columnsFromBdd: Object[] = [];
  tabToPrint: Array<any> = [];
  rows = [];
  currentImages = [];
  selectedColumn = 'all';
  showFiltersBool = false;
  allFournisseurs;
  allSites;
  filtersToApply = {};
  excelData = [];

  constructor(private HuilesEtGraissesService: HuilesEtGraissesService, private route: Router,
    private http: HttpClient, private authService: AuthService, private fournisseurs: FournisseursService) {
  }

  // Récupère toutes les tables dans le fichier conf.json //

  ngOnInit(): void {
    if (document.querySelector('.aside').classList.contains('active') || document.getElementById("modal-user").classList.contains("active")) {
      document.querySelector('.aside').classList.remove('active');
      document.getElementById("modal-user").classList.remove("active");
    }

    if (!this.authService.canSee('huiles_graisses')) this.route.navigate(['/dashboard']);

    if (this.authService.userRole != "admin") {
      this.http.get(`${this.url}/sites/${this.authService.userId}`).toPromise().then((res) => {
        this.allSites = res;
      });
    } else if (this.authService.userRole == "admin") {
      this.http.get(`${this.url}/sites/0`).toPromise().then((res) => {
        this.allSites = res;
      });
    }

    this.fournisseurs.initFournisseurs().then(() => {
      this.allFournisseurs = this.fournisseurs.fournisseurs;
    });

    this.http.get(`${this.url}/conf/get`).toPromise().then((res) => {
      this.data = res;
      for (let i = 0; i < this.data["data"].length; i++) {
        for (let j = 0; j < this.data.data[i]["whereToDisplay"].length; j++) {
          if (this.data.data[i]["whereToDisplay"][j]["nameOfTab"] === "huilesEtGraisses") {
            if (this.data.data[i]["isChecked"] === true) {
              this.columns.push(this.data.data[i]);
            }
          }
        }
      }
      for (let k = 0; k < this.data.data.length; k++) {
        for (let l = 0; l < this.data.data[k]["belongsTo"].length; l++) {
          if (this.data.data[k]["belongsTo"][l]["table"] === "huilesEtGraisses") {
            this.columnsFromBdd.push(this.data.data[k]);
          }
        }
      }

      // Modal de chargement qui se ferme après initialisation de tous les éléments //

      swal.fire({
        title: 'Chargement...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading()
        }
      });

      this.HuilesEtGraissesService.initHuilesEtGraisses().then((response) => {
        this.temp = this.HuilesEtGraissesService.huilesEtGraisses;
        this.mapTables();
        swal.close()
      });
    }, (err) => {
      console.log(err);
    });
  }

  // Modification d'un élément //

  update(obj) {
    for (let [key, value] of Object.entries(this.activeRow)) {
      typeof value === 'string' && (value = value.replace(/<[^>]*>/g, ''));
      this.activeRow[key] = value;
    }
    this.HuilesEtGraissesService.huileEtGraisse = this.activeRow;
    this.authService.tableName = obj;
    this.route.navigate(['modifier']);
  }

  // Supression d'un élément //

  delete() {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer l\'élément ?',
      text: "Cette action est irréversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value && this.authService.canSee('delete_huiles_graisses')) {
        this.HuilesEtGraissesService.removeHuilesEtGraisses(this.activeRow['idHuile']).then((value) => {
          swal.fire({
            title: 'Suppression réussie !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          }).then(() => {
            // this.http.get(`${this.url}/getDemandeById/${this.activeRow['idHuile']}`).toPromise().then((res) => {
            //   if (res != 0) this.http.delete(`${this.url}/delete_demande/${res}`).toPromise().then((res) => { });
            // });
            this.HuilesEtGraissesService
              .initHuilesEtGraisses()
              .then((value2) => {
                this.temp = this.HuilesEtGraissesService.huilesEtGraisses;
                this.showArchives = false;
                this.tabToPrint = this.temp;
                this.mapTables();
              });
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Suppression annulée !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    })
  }

  // Ajout d'un élément aux sites de l'utilisateur //

  addToMySites() {
    if (this.allSites.length == 0) {
      swal.fire({
        title: 'Votre profil n\'est lié à aucun site !',
        icon: 'error',
        showConfirmButton: false,
        timer: 1000
      });
      return;
    }
    if (this.activeRow['linked_sites']) {
      const sitesOfUser = this.activeRow['linked_sites'].split(' / ');
      sitesOfUser.map(nomSite => ({ nom_site: nomSite }));
      let count: number = 0;
      for (let site of this.allSites) {
        if (sitesOfUser.includes(site['NomSite'])) {
          count++;
        } else {
        }
      }
      if (count == this.allSites.length) {
        swal.fire({
          title: 'Cet élément est déjà présent sur tous vos sites !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
        return;
      }
    }
    swal.fire({
      title: 'Êtes-vous sûr de vouloir ajouter cet élément à vos sites ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value && this.authService.canSee('take_huiles_graisses')) {
        for (let site of this.allSites) {
          var obj = {
            "id": this.activeRow['idHuile'],
            "idSite": site['IdSite']
          }
          this.http.post(`${this.url}/huilesEtGraisses/addElementToSite`, obj).toPromise().then((res) => { });
        }
        swal.fire({
          title: 'Ajout réussi !',
          icon: 'success',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          this.HuilesEtGraissesService
            .initHuilesEtGraisses()
            .then((value2) => {
              this.temp = this.HuilesEtGraissesService.huilesEtGraisses;
              this.showArchives = false;
              this.tabToPrint = this.temp;
              this.mapTables();
            });
        });
      }
    });
  }

  // Affiche/Cache la modal de GED et vérifie la présence de document //

  ged() {
    if (document.getElementById("Huile-" + this.activeRow['idHuile']).classList.contains("active")) {

    } else {
      this.loading = true;
      this.http.get(`${this.url}/file/huiles/getFilesNameById/${this.activeRow["idHuile"]}`).toPromise().then((res) => {
        this.loading = false;
        this.currentImages = [];

        for (let i = 0; i < Object.keys(res).length; i++) {
          this.http.get(`${this.url}/file/huiles/isImage/${res[i]["Fichier"]}`).toPromise().then((res2) => {
            var jsonData = {};
            jsonData["name"] = res[i]["Fichier"];
            jsonData["fileName"] = res[i]["Texte"];
            jsonData["isImage"] = res2;
            jsonData["creator"] = res[i]["Creator"];
            jsonData["DateCreation"] = res[i]["DateCreation"].split("T")[0] + " " + res[i]["DateCreation"].split("T")[1];
            this.currentImages.push(jsonData);
          });
        }
      });
    }
    document.getElementById("Huile-" + this.activeRow['idHuile']).classList.toggle("active");
  }

  // Ajoute un document au GED de l'élément puis cache la modal //

  addGed(event) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir ajouter ce document ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value) {
        const file: File = event.target.files[0];
        const formData = new FormData();

        formData.append("thumbnail", file);

        this.http.post(`${this.url}/file/huiles/post`, formData).toPromise().then((res) => {
          let obj = {
            id: this.activeRow["idHuile"],
            idGed: res,
            creator: this.authService.user.prenom
          }
          this.http.post(`${this.url}/file/huiles/updateGED`, obj).toPromise().then((res2) => {
            this.ged();
          });
          swal.fire({
            title: 'Ajout réussi !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Ajout annulé !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Supprime le document séléctionné du GED de l'élément puis cache la modal //

  deleteGed(fileName) {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then(result => {
      if (result.value) {
        this.http.get(`${this.url}/file/huiles/deleteFileByName/${fileName}`).toPromise().then((res) => {
          this.ged();
          swal.fire({
            title: 'Suppression réussie !',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
          });
        })
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.fire({
          title: 'Suppression annulée !',
          icon: 'error',
          showConfirmButton: false,
          timer: 1000
        });
      }
    });
  }

  // Change la colonne de recherche //

  changeFilter($event) {
    this.selectedColumn = $event.target.value;
  }

  // Méthode recherche dans le tableau //

  search($event, selectedColumn) {
    if (!$event.target.value.length) {
      this.tabToPrint = this.rows;
      return;
    }

    const val = $event.target.value.toLowerCase();
    const rows = this.rows;

    this.tabToPrint = rows.filter(row => {
      return selectedColumn !== 'all'
        ? row[selectedColumn].toString().toLowerCase().indexOf(val) !== -1
        : Object.values(row).some(value => value && value.toString().toLowerCase().indexOf(val) !== -1);
    }).map(row => {
      let newObj = {};
      for (let key in row) {
        if (row[key] != null) {
          const str = row[key].toString();
          const lowerCaseStr = str.toLowerCase();
          if (lowerCaseStr.indexOf(val) !== -1) {
            newObj[key] = str.replace(new RegExp(val, 'gi'), `<span class="highlight">${str.substr(lowerCaseStr.indexOf(val), val.length)}</span>`);
          } else {
            newObj[key] = str;
          }
        }
      }
      return newObj;
    });
  }

  // Méthode pour filtrer //

  async onSubmit(form: NgForm) {
    this.filtersToApply = {};
    for (const key in form.value) {
      if (form.value[key] == null || form.value[key] == undefined || form.value[key] == "Tout" || form.value[key] == " " || form.value[key] == "all") {
        form.value[key] = "";
      }

      if (form.value[key] != "") {
        this.filtersToApply[key] = form.value[key];
      }

      if (form.value[key] == "mes_sites") {
        this.filtersToApply[key] = "";
        this.HuilesEtGraissesService.initHuilesEtGraisses(0).then((res) => {
          this.temp = this.HuilesEtGraissesService.huilesEtGraisses;
          this.mapTables();
        });
      }
    }
    this.filter();
  }

  // Réinitialise les filtres //

  resetFilters() {
    this.filtersToApply = {};
    this.tabToPrint = this.rows;
    this.showFilters();
  }

  // Affiche les filtres //

  showFilters() {
    this.showFiltersBool = !this.showFiltersBool;
  }

  // Méthode pour filtrer le tableau //

  filter() {
    this.tabToPrint = this.rows;
    for (const key in this.filtersToApply) {
      if (this.filtersToApply[key] != "") {
        this.tabToPrint = this.tabToPrint.filter(row => {
          if (row[key] == null) {
            return false;
          }
          if (row[key].toString().toLowerCase().indexOf(this.filtersToApply[key].toLowerCase()) !== -1) {
            return true;
          }
        });
      }
    }
  }

  // Methode pour associer l'id fournisseur et le nom du fournisseur, tronque les dates //

  mapTables() {
    let objTmp = {};
    this.tabToPrint = [];
    for (let i = 0; i < this.temp.length; i++) {
      objTmp = {};
      objTmp["idHuile"] = this.temp[i]['idHuile'];
      objTmp["idFournisseur"] = this.temp[i]['idFournisseur'];
      objTmp["archive"] = this.temp[i]['archive'];
      for (var col of this.columnsFromBdd) {
        objTmp[col["name"]] = this.temp[i][col["bddName"]];
        if (col["bddName"] == "validite" || col["bddName"] == "date_document" || col["bddName"] == "derniere_verification") {
          if (this.temp[i][col["bddName"]] != null) {
            objTmp[col["name"]] = this.temp[i][col["bddName"]].split("T")[0];
          }
        }
      }
      objTmp["Nom du fournisseur"] = this.temp[i]['idFournisseurNavigation']['nomFournisseur'];
      if (this.temp[i]['associationSitesHuiles'] != undefined && this.temp[i]['associationSitesHuiles'].length > 0) {
        for (let j = 0; j < this.temp[i]['associationSitesHuiles'].length; j++) {
          if (j != 0) {
            if (this.temp[i]['associationSitesHuiles'][j]["idSiteNavigation"]['nomSite'] != null) {
              objTmp["Site(s)"] += " / " + this.temp[i]['associationSitesHuiles'][j]["idSiteNavigation"]['nomSite'];
            }
          } else if (j == 0) {
            if (this.temp[i]['associationSitesHuiles'][j]["idSiteNavigation"]['nomSite'] != null) {
              objTmp["Site(s)"] = this.temp[i]['associationSitesHuiles'][j]["idSiteNavigation"]['nomSite'];
            }
          }
        }
      }
      this.tabToPrint.push(objTmp);
    }
    this.rows = this.tabToPrint;
  }

  // Methode onActivate, utilisée pour le tableau //

  onActivate(event) {
    const tabToMap = {};
    tabToMap["idHuile"] = event.row['idHuile'];
    tabToMap["idFournisseur"] = event.row['idFournisseur'];
    tabToMap["archive"] = event.row['archive'];
    for (var col of this.columnsFromBdd) {
      tabToMap[col["bddName"]] = event.row[col["name"]];
    }
    this.activeRow = tabToMap;
  }

  // Afficher plus ou moins d'éléments dans le tableau //

  changeEntries($event) {
    this.entries = $event.target.value;
  }

  // Afficher les éléments archivés ou non //

  changeArchives($event) {
    if ($event.target.value == "true") {
      if (this.authService.canSee('archives')) {
        this.showArchives = true;
        swal.fire({
          title: 'Chargement...',
          allowOutsideClick: false,
          onBeforeOpen: () => {
            swal.showLoading()
          }
        });
        this.http
          .get(`${this.url}/huilesEtGraisses/archives`)
          .toPromise()
          .then((response) => {
            this.temp = [];
            this.temp = response;
            this.mapTables();
            swal.close();
          });
      } else {
        swal.close();
        this.HuilesEtGraissesService.initHuilesEtGraisses().then((res) => {
          this.temp = this.HuilesEtGraissesService.huilesEtGraisses;
          this.mapTables();
        });
      }
    } else if ($event.target.value == "false") {
      this.showArchives = false;
      swal.fire({
        title: 'Chargement...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading()
        }
      });
      this.http
        .get(`${this.url}/huilesEtGraisses/${this.HuilesEtGraissesService.paramUserRole}`)
        .toPromise()
        .then((response) => {
          this.temp = [];
          this.temp = response;
          this.mapTables();
          swal.close();
        });
    }
  }

  // Vérifie la valeur de la case pour modifier le css //

  cellClass({ row, column, value }: any) {
    // Extraire le texte brut sans les balises HTML
    const textContent = value.replace(/<[^>]*>/g, '');

    if (textContent === "Certificat périmé" || textContent === "Certificat caduque") {
      return 'red';
    } else if (textContent === "Ok") {
      return 'green';
    } else if (textContent === "Manque date de validité !") {
      return 'orange';
    }
    const today: Date = new Date();
    const validite: Date = new Date(textContent);
    if (today > validite && column.name == "Validité") {
      return 'red';
    } else if (today < validite && column.name == "Validité") {
      return 'green';
    }
  }

  // Redirige vers la page d'ajout //

  ajout(obj) {
    this.authService.tableName = obj;
    this.route.navigate(['/ajout']);
  }

  // Archive l'élément //

  archive() {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir archiver cet élément ?',
      showCancelButton: true,
      confirmButtonText: `Oui`,
      cancelButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.get(`${this.url}/huilesEtGraisses/archive/${this.activeRow["idHuile"]}`).toPromise().then((res) => {
          this.changeArchives({ target: { value: "true" } });
          swal.close();
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.close();
      }
    });
  }

  // Désarchive l'élément //

  unArchive() {
    swal.fire({
      title: 'Êtes-vous sûr de vouloir désarchiver cet élément ?',
      showCancelButton: true,
      confirmButtonText: `Oui`,
      cancelButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.get(`${this.url}/huilesEtGraisses/unArchive/${this.activeRow["idHuile"]}`).toPromise().then((res) => {
          this.changeArchives({ target: { value: "false" } });
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.close();
      }
    });
  }

  // Contoure la ligne sélectionnée //

  onRowClick(event) {
    const selectedElement: HTMLElement = event.target.closest('.datatable-body-row');
    const previouslySelectedElement: HTMLElement = this.table.element
      .querySelector('.datatable-body-row.row-selected');

    if (previouslySelectedElement) {
      previouslySelectedElement.style.backgroundColor = '';
      previouslySelectedElement.classList.remove('row-selected');
      if (previouslySelectedElement === selectedElement) {
        return;
      }
    }

    if (selectedElement) {
      selectedElement.classList.add('row-selected');
    }
  }

  // Vérifie la validité des éléments et met à jour le commentaire_validite //

  verifyValidite() {
    swal.fire({
      title: 'Voulez-vous mettre à jour les commentaires de validité ?',
      showCancelButton: true,
      confirmButtonText: `Oui`,
      cancelButtonText: `Non`,
    }).then((result) => {
      if (result.isConfirmed) {
        swal.fire({
          title: 'Êtes-vous sûr ?',
          text: 'Cette action est irréversible !',
          showCancelButton: true,
          confirmButtonText: `Oui`,
          cancelButtonText: `Non`,
        }).then((result) => {
          if (result.isConfirmed) {
            const today: Date = new Date();
            swal.fire({
              title: 'Chargement...',
              allowOutsideClick: false,
              onBeforeOpen: () => {
                swal.showLoading()
              }
            });
            for (let i = 0; i < this.temp.length; i++) {
              if (this.temp[i]["validite"] != null) {
                const validite: Date = new Date(this.temp[i]["validite"]);
                if (today > validite && this.temp[i]["validite"]) {
                  this.temp[i]["commentaire_validite"] = "Certificat caduque";
                  this.HuilesEtGraissesService.updateHuilesEtGraisses(JSON.parse(JSON.stringify(this.temp[i]))).then((response) => {
                  });
                } else if (today < validite) {
                  this.temp[i]["commentaire_validite"] = "Ok";
                  this.HuilesEtGraissesService.updateHuilesEtGraisses(JSON.parse(JSON.stringify(this.temp[i]))).then((response) => {
                  });
                } else {
                }
              } else if (this.temp[i]["validite"] == null) {
                this.temp[i]["commentaire_validite"] = "Manque date de validité !";
                this.HuilesEtGraissesService.updateHuilesEtGraisses(JSON.parse(JSON.stringify(this.temp[i]))).then((response) => {
                });
              }
            }
            this.HuilesEtGraissesService.initHuilesEtGraisses().then((response) => {
              this.mapTables();
              swal.close();
            });
          } else if (result.dismiss === swal.DismissReason.cancel) {
            swal.close();
          }
        });
      } else if (result.dismiss === swal.DismissReason.cancel) {
        swal.close();
      }
    });
  }

  createDemande(type) {
    this.authService.tableName = type;
    this.route.navigate(['/creer-demande']);
  }

  exportToExcel() {
    this.excelData = this.rows;
    for (let i = 0; i < this.excelData.length; i++) {
      delete this.excelData[i]["archive"];
      delete this.excelData[i]["idHuile"];
      delete this.excelData[i]["idFournisseur"];
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const columns = Object.keys(this.excelData[0]);
    worksheet.columns = columns.map((column) => ({
      header: column,
      key: column,
      width: 15,
    }));

    worksheet.columns[0] = { header: 'N°', key: 'idHuile', width: 5 };

    worksheet.addRows(this.excelData);

    worksheet.getRow(1).font = { bold: true };

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'HuilesEtGraisses.xlsx';
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }
}