import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { ModalModule } from "ngx-bootstrap/modal";

import { ParametersComponent } from "./parameters.component";

import { ParametersRoutes } from "./parameters.routing";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(ParametersRoutes),
    ModalModule.forRoot()
  ],
  declarations: [ParametersComponent],
  exports: [ParametersComponent]
})
export class ParametersModule {}
