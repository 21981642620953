import { Routes } from "@angular/router";

import { FournisseursComponent } from "./fournisseurs.component";

export const FournisseursRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: FournisseursComponent
      }
    ]
  }
];
